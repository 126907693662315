<template>
  <div class="menber-dialog">
    <HeTong
      ref="showDetail3"
      v-if="showHt"
      :currentItem="currentItem"
      :currentCode="currentCode"
    />
    <el-dialog
      title="站内消息"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      width="600px"
    >
      <el-form :inline="true" label-width="160px">
        <el-form-item label="托管人:" style="width: 240px">
          <!-- {{ currentItem.farmerName.substr(0,1)+"···" }} -->
          <!-- {{ currentItem.farmerName.substr(0,1)+(currentItem.sex==2? "女士" :"先生") }} -->
          {{ currentItem.farmerName }}
        </el-form-item>
        <el-form-item label="联系电话:">
          {{
            currentItem.farmerPhone.slice(0, 3) +
            "****" +
            currentItem.farmerPhone.slice(-4)
          }}
        </el-form-item>
        <el-form-item label="托管类型:" style="width: 240px">
          {{ currentItem.orderType == 1 ? "全程托管" : "环节托管" }}
        </el-form-item>
        <el-form-item label="托管环节:" style="width: 100%"
          ><span class="word">{{
            currentItem.orderContent
          }}</span></el-form-item
        >
      </el-form>
      <el-form ref="form" label-width="160px">
        <el-form-item label="作物类型:">{{
          currentItem.cropCode
        }}</el-form-item>
        <el-form-item label="每季保底产量:"
          >{{ currentItem.seasonYield }}KG/亩</el-form-item
        >
        <el-form-item label="土地面积:">
          {{ currentItem.landArea }} 亩</el-form-item
        >
        <el-form-item label="地址:"> {{ currentItem.areaName }} </el-form-item>
        <el-form-item label="托管时间:"
          >{{
            currentItem.startDate ? currentItem.startDate.slice(0, 10) : ""
          }}~{{
            currentItem.endDate ? currentItem.endDate.slice(0, 10) : ""
          }}</el-form-item
        >
        <el-form-item label="总价:"
          >{{ currentItem.estimatePrice }}元</el-form-item
        >
        <el-form-item label="其他说明:">{{ currentItem.remark }}</el-form-item>
        <el-form-item label="现场照片:">
          <el-image
            style="width: 100px; height: 100px"
            :src="item"
            v-for="(item, index) in currentItem.xczp"
            :key="index"
            :preview-src-list="currentItem.xczp"
          >
          </el-image>
        </el-form-item>
      </el-form>
      <div class="btn" style="text-align: center">
        <el-button @click="back">关闭</el-button>
        <el-button @click="collect">收藏</el-button>
        <!-- <el-button @click="goout2" type="primary"
          >我要接管并导出合同</el-button
        > -->
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import HeTong from "./hetong.vue";
import { collectionOrder } from "@/api/add";
export default {
  data() {
    return {
      title: "详情",
      currentCode: 2,
      showHt: false,
      dialogVisible: true,
      baseUrl: window.globalUrl.HOME_API,
      currentItem: {},
      num: 0,
    };
  },

  components: {
    HeTong,
  },
  mounted() {},
  computed: mapGetters(["userInfo", "identityObj"]),
  watch: {},
  methods: {
    async collect() {
      if (!this.identityObj.identityTypeCode) {
        this.$message.info("请登录后进行操作");
        return;
      }
      if (this.identityObj.identityTypeCode == 2) {
        this.$message.info("当前账户无权限，请切换新型经营主体账户后操作");
        return;
      }
      let res = await collectionOrder({ orderId: this.currentItem.orderId });
      if (res.code == 0) {
        this.$message.success(res.msg);
      } else {
        this.$message.error(res.msg);
      }
    },
    async Online() {
      // const jgdxq = await companyByid({
      //   id: this.currentItem.id,
      // });
      const jgdxq = this.currentItem;

      let xczp = [];
      if (jgdxq.photos) {
        JSON.parse(jgdxq.photos).forEach((item) => {
          xczp.push(this.baseUrl + "admin/file/get?ossFilePath=" + item.url);
        });
      }
      jgdxq.xczp = xczp;

      let companyPhoto = [];
      if (jgdxq.companyPhoto == "" || jgdxq.companyPhoto == null) {
        companyPhoto = [];
      } else if (typeof jgdxq.companyPhoto === "string") {
        const arr = jgdxq.companyPhoto.split(",");
        arr.forEach((item) => {
          companyPhoto.push(
            this.baseUrl + "admin/file/get?ossFilePath=" + item
          );
        });
      } else {
        companyPhoto = jgdxq.companyPhoto;
      }
      jgdxq.companyPhoto = companyPhoto;

      console.log("接管单详情", jgdxq);
      console.log("供应信息详情111-Online");
      if (this.currentItem.authId == this.identityObj.identityId) {
        this.$message.info("本人项目");
      } else {
        let content2 = JSON.stringify(jgdxq);
        console.log("wxf", content2);
        let params = {
          messageList: [
            {
              //消息类型会话托管
              messageType: 5,
            },
          ],
          receiverId: this.currentItem.authId,
          senderType: this.identityObj.identityTypeCode,
          //接受消息人类型只有公司认证用户
          receiverType: 4,
          senderId: this.identityObj.identityId,
          sender: this.identityObj.realName,
          receiver: this.currentItem.companyName,
        };
        let res = await SendMessage(params);
        if (res.code !== 1) {
          this.$router.push({
            path: "/home/Chat",
            query: {
              isshow: true, // 你要传递的参数
              data: jgdxq,
              num: 1,
            },
          });
          // this.$router.push({ name: 'Chat', params: { isshow: true, data: jgdxq, num: 1 } });
        }
      }
    },


    // goout2() {
    //   if (!this.identityObj.identityTypeCode) {
    //     this.$message.info("请登录后进行操作");
    //     return;
    //   }
    //   if (this.currentItem.orderState === "01") {
    //     this.$message.info("托管中，不支持导出合同");
    //     return;
    //   }
    //   if (this.currentItem.orderState === "02") {
    //     this.$message.info("托管结束，不支持导出合同");
    //     return;
    //   }
    //   // if (this.identityObj.identityTypeCode != 4) {
    //   //   this.$message.info("当前账户无权限，请切换新型经营主体账户后操作1111");
    //   //   return;
    //   // }

    //   if (this.identityObj.identityTypeCode == 4) {
    //     console.log("this.currentItem", this.currentItem);
    //     this.showHt = true;
    //     setTimeout(() => {
    //       // this.$refs.hetong.viewHt2(this.currentItem.orderId);
    //       this.$refs.hetong.viewHt2(this.currentItem.orderId);
    //     }, 100);
    //   }
    //   if (this.identityObj.identityTypeCode == 2) {
    //     console.log("2");
    //     this.showHt = true;
    //     this.currentCode = 1;
    //     setTimeout(() => {
    //       // this.$refs.hetong.viewHt2(this.currentItem.orderId);
    //       this.$refs.hetong.viewHt(this.currentItem, this.num);
    //     }, 100);
    //   }

    //   //       setTimeout(() => {
    //   //     this.$refs.hetong.viewHt2();
    //   // }, 100);
    // },
    init(obj, num) {
      this.num = num;
      console.log("222num", num, obj);
      // let xczp = [];
      // if (obj.livePhotos) {
      //   JSON.parse(obj.livePhotos).forEach((item) => {
      //     xczp.push(this.baseUrl + "admin/file/get?ossFilePath=" + item.url);
      //   });
      // }
      // obj.xczp = xczp;
      // let pzzp = [];
      // if (obj.landCertificateUrl) {
      //   JSON.parse(obj.landCertificateUrl).forEach((item) => {
      //     pzzp.push(this.baseUrl + "admin/file/get?ossFilePath=" + item.url);
      //   });
      // }
      // obj.pzzp = pzzp;
      // this.currentItem = obj;
    },
    back() {
      this.$emit("closeDialog3", false);
    },
    handleClose() {
      this.$emit("closeDialog3", false);
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-form-item {
  margin-bottom: 0 !important;
  .el-input {
    width: 260px;
  }
}
/deep/.el-dialog__body {
  padding-left: 30px;
  padding-right: 60px !important;
}
/deep/.el-image {
  margin-right: 4px;
}
.word {
  display: inline-block;
  width: 347px;
}
/deep/.el-dialog {
  margin-top: 6vh !important;
}
</style>
