<template>
  <div class="hetong">
    <el-dialog
      title="合同预览"
      :visible.sync="showHt"
      width="1000px"
      custom-class="ht"
      @close="close"
    >
      <div v-if="currentItem.receiverType == 4">
        
        <span style="padding-left: 60px">选择已发布托管土地：</span>
        <el-select
          @change="changeOrder"
          v-model="selectOrder"
          style="width: 400px; margin: 20px 0"
        >
          <el-option value="1" label="未发布地块">未发布地块</el-option>
          <el-option
            v-for="(item, index) in orders"
            :key="index"
            :value="item.id"
            :label="item.Title"
            >{{ item.Title }}</el-option
          >
        </el-select>
      </div>
      <div style="height: 20px"></div>
      <div
        class="content"
        ref="printContent"
        v-loading="loading"
        element-loading-text="加载中"
      >
        <h2
          v-if="!pdfSrc && !loading"
          style="line-height: 500px; text-align: center"
        >
          {{ loadingText }}
        </h2>
        <div v-if="pdfSrc" class="pdfView">
          <div v-for="page in pages" :key="page" class="pdf-page">
            <pdf :src="pdfSrc" :page="page"> </pdf>
          </div>
        </div>
      </div>
      <!-- <div style="text-align: center" v-if="pdfSrc">
        <el-button
          size="small"
          type="info"
          @click="prePage()"
          class="turn"
          :class="{ grey: pageNum == 1 }"
          >上一页</el-button
        >
        {{ pageNum }} / {{ pageAllNum }}
        <el-button
          size="small"
          type="info"
          @click="nextPage()"
          class="turn"
          :class="{ grey: pageNum == pageAllNum }"
          >下一页</el-button
        >
      </div> -->
      <p
        style="color: red; text-align: center"
        v-show="identityObj.identityTypeCode == 4"
      >
        *导出前请确认已与托管人沟通，并就托管事项达成一致
      </p>
      <div style="text-align: center" v-if="identityObj.identityTypeCode == 2">
        <el-button @click="exportHts" type="primary" v-if="this.ismy == 1"
          >导出</el-button
        >
        <el-button @click="close" type="primary" v-else-if="this.ismy == 2"
          >邀请在线沟通</el-button
        >
        <el-button disabled type="info" v-else-if="this.ismy == 0"
          >他人已成交</el-button
        >
        <!-- <div v-else>请选择托管地块</div> -->
      </div>
      <div style="text-align: center" v-if="identityObj.identityTypeCode == 4">
        <el-button @click="exportHts" type="primary" v-if="this.ismy == 1"
          >导出</el-button
        >
        <el-button @click="close" type="primary" v-else-if="this.ismy == 2"
          >邀请在线沟通</el-button
        >
        <el-button disabled type="info" v-else-if="this.ismy == 0"
          >他人已成交</el-button
        >
        <!-- <div v-else>请选择托管地块</div> -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
import pdf from "vue-pdf";
import { getCompanyList, getOrderList } from "@/api/projectManage.js";
import {
  companyPusgPdf,
  orderPushPdf,
  getOrderNotPush,
  updateCompanyState,
  updateOrderState,
  // nhExportCheck,
  companyExportCheck,
} from "@/api/add";
import { selectCompany } from "../../api/websocket";
import { mapGetters } from "vuex";
export default {
  props: ["currentCode", "currentItem"],
  components: {
    pdf,
  },
  computed: {
    ...mapGetters(["identityObj"]),
    topSelectAreacode: {
      get() {
        return this.$store.state.user.topSelectAreacode;
      },
      set(val) {
        return (this.$store.state.user.topSelectAreacode = val);
      },
    },
  },
  data() {
    return {
      receiverId: "",
      preview: false,
      ismy: 4,
      loadingText: "请选择托管地块",
      pages: [1, 2, 3, 4, 5, 6, 7],
      exportFlag: false,
      loading: false,
      baseUrl: window.globalUrl.HOME_API,
      form1Params: {},
      form2Params: {},
      htName: "",
      pdfSrc: "", // pdf文件src
      pageAllNum: 7, // pdf文件总页数
      pageNum: 1, // pdf文件页码
      selectOrder: "",
      orders: [],
      showHt: true,
      searchAreas: [],
      // currentCode: 1托管导出 2接管导出,
      dialogVisible: false,
      demandVisible: false,
      keyForm: 1, //下拉框为请求来的数据时监听会用到
      serviceType: [
        {
          item: "1",
          name: "全程托管",
        },
        {
          item: "2",
          name: "环节托管",
        },
      ],
      regionOptions: [{}],
      props: {
        label: "xzqmc",
        children: "list",
        value: "xzqbh",
        checkStrictly: true,
        emitPath: false,
        expandTrigger: "hover",
      },
      options: [],
      statetion: [
        { item: "00", name: "未托管" },
        { item: "01", name: "托管中" },
        { item: "02", name: "托管结束" },
      ],
      options3: [],
      form1: {
        serviceScope: [],
        address: "",
        intentionRegion: "",
      },
      form2: {
        cropCode: [],
        orderContent: [],
        areaCode: "",
      },
      tableData: [],
      tableData1: [],
      total: 0,

      params: {
        pnum: 1,
        psize: 10,
      },
      list: [
        {
          id: "0",
          name: "宣汉县县运营公司名称",
          lot: 107.95,
          lat: 31.4,
        },
        {
          id: "1",
          name: "宣汉县测试公司名称",
          lot: 108,
          lat: 31.5,
        },
      ],
      globalUrl: `${window.globalUrl.HOME_API_WEI}`,
      nongshiArr: [],
      zuowuArr: [],
      curren: {}, // 订单数据
    };
  },
  created() {
    
    if (this.identityObj.identityTypeCode == 4 || this.identityObj.identityTypeCode == 2) {
      this.chakan();
    }
  },
  mounted() {
    if(this.identityObj.identityTypeCode==2){
    this.getOrders();
    }

    console.log("orders", this.orders);
    // console.log("currentCode",this.currentCode);
  },
  methods: {
    async chakan() {
     
      console.log("this.selectOrder121212", this.selectOrder);
      // if(this.selectOrder && this.currentItem.orderId ) return
      const res = await selectCompany({
        orderId: this.selectOrder
          ? this.selectOrder
          : this.currentItem.orderId
          ? this.currentItem.orderId
          : this.currentItem.id,
      });

      //     data 失败 0:别人成交的  成功 1：自己成交的  失败 2：未成交的
      // code : 0 返回成功  1返回失败
      this.ismy = res.data;
      if (res.data == 0) {
        this.$message.error(res.msg);
      }
      console.log("this.selectOrder", res.msg);
    },

    close() {
      this.$parent.showHt = false;
      // this.chakan()
    },
    async viewHt(val, num) {
      console.log("111", val, num);
      // this.chakan();
      console.log("王潇锋", this.currentItem);
      if (!val) {
        val = this.selectOrder;
      }
      let params = {
        companyId: num ? num : this.currentItem.authId,
        orderId: val.orderId ?  val.orderId : this.selectOrder,
        preview: this.preview,
      };
      console.log('121321321------',params);
      let res = await orderPushPdf(params);
      this.loading = true;
      this.receiverId = num; // 赋值
  

      this.loading = false;
      if (res) {
        const blob = new Blob([res], { type: "application/pdf;charset=utf-8" });
        this.pdfSrc = window.URL.createObjectURL(blob);
      } else {
        this.loadingText = "预览失败";
        this.$message({
          message: res.message,
          type: "error",
        });
      }
    },
    pdfError(error) {
      this.loading = false;
      if (this.exportFlag) {
        this.$message.error("导出失败");
      } else {
        this.pdfSrc = null;
      }
    },
    async viewHt2() {
      let params = {
        orderId: this.currentItem.orderId,
      };
      this.loading = true;
      let res = await companyPusgPdf(params);
      const reader = new FileReader();
      reader.readAsText(res, "utf-8");
      reader.onload = function () {
        console.log("res1", reader.result);
      };
      //  console.log("res1",res);
      this.loading = false;
      if (res) {
        const blob = new Blob([res], { type: "application/pdf;charset=utf-8" });
        this.pdfSrc = window.URL.createObjectURL(blob);
      } else {
        this.loading = false;
        this.$message({
          message: res.message,
          type: "error",
        });
      }
    },
    exportHts() {
      this.preview = true;
      this.exportFlag = true;
      if (this.currentCode == 1) {
        this.exportHt();
      } else {
        this.exportHt2();
      }
      this.preview = false;
    },
    // 导出合同
    async exportHt() {
      // if (!this.selectOrder) {
      //   this.$message.info("请选择已发布的托管土地");
      //   return;
      // }
      let params = {
        companyId: this.receiverId,
        orderId: this.currentItem.orderId,
        preview: this.preview,
      };
      console.log("params", params);
      let res = await orderPushPdf(params);
      console.log("wxf");
      await updateOrderState(params);
      try {
        this.$message.success("合同导出成功");
        const blob = new Blob([res], { type: "application/pdf;charset=utf-8" });
        const url = window.URL.createObjectURL(blob);
        let dom = document.createElement("a");
        dom.style.display = "none";
        dom.href = url;
        let name =
          this.currentItem.areaName +
          this.currentItem.farmerName +
          this.currentItem.cropCode +
          (this.currentItem.orderType == 1 ? "全程托管" : "环节托管");
        console.log("name11111111",name);
        dom.setAttribute("download", this.htName ? this.htName : name);
        document.body.appendChild(dom);
        dom.click();
      } catch (error) {
        console.log(error);
      }
    },
    async exportHt2() {
      let params = {
        orderId: this.currentItem.orderId,
        preview: this.preview,
        // orderId: "0469009792e7250132db2c693235ba50",
      };
      await updateCompanyState(params);
      let res = await companyPusgPdf(params);
      // console.log("res2",res);
      try {
        this.$message.success("合同导出成功");
        const blob = new Blob([res], { type: "application/pdf;charset=utf-8" });
        const url = window.URL.createObjectURL(blob);
        let dom = document.createElement("a");
        dom.style.display = "none";
        dom.href = url;
        // console.log(url);
        let name =
          this.currentItem.areaName +
          this.currentItem.farmerName +
          this.currentItem.cropCode +
          (this.currentItem.orderType == 1 ? "全程托管" : "环节托管");
        console.log("name11111111",name);
        dom.setAttribute("download", name);
        document.body.appendChild(dom);
        dom.click();
      } catch (error) {
        console.log(error);
      }
    },
    prePage() {
      var page = this.pageNum;
      page = page > 1 ? page - 1 : this.pageTotalNum;
      this.pageNum = page;
    },
    // 下一页函数
    nextPage() {
      var page = this.pageNum;
      page = page < this.pageTotalNum ? page + 1 : 1;
      this.pageNum = page;
    },
    async changeOrder(id) {
      // let res = await nhExportCheck({
      //   companyId: this.currentItem.id,
      //   orderId: id,
      // });
      // if (res.code == 0) {
      this.pageNum = 1;
      this.orders.forEach((item) => {
        if (item.id === id) {
          this.htName =
            item.areaName +
            item.farmerName +
            item.cropCode +
            (item.orderType == 1 ? "全程托管" : "环节托管");
        }
      });
      this.viewHt();
      // } else {
      //   this.$message.info(res.msg);
      // }
    },
    async getOrders(val, num) {
      this.curren = val;
      this.receiverId = num; // 赋值
      console.log("getOrders", val, num);
      let res = await getOrderNotPush();
      if (res.code == 0) {
        res.data.forEach((item) => {
          item.Title =
            item.areaName +
            (item.orderType == 1 ? "全程托管" : "环节托管") +
            item.cropCode +
            "共" +
            item.landArea +
            "亩";
        });
        this.orders = res.data;
      } else {
        this.orders = [];
      }
    },
    goout() {
      if (!this.identityObj.identityTypeCode) {
        this.$message.info("请登录后进行操作");
        return;
      }
      this.selectOrder = "";
      this.getOrders();
    },
    search(params) {
      this.form1Params = {
        areaCode: params.areaCode
          ? params.areaCode[params.areaCode.length - 1]
          : null,
        intentionRegionCode: params.intentionRegionCode
          ? params.intentionRegionCode[params.intentionRegionCode.length - 1]
          : null,
        companyName: params.companyName,
        serviceType: params.serviceType,
        serviceScope: params.serviceScope,
        pnum: 1,
      };
      this.searchFuc();
    },
    reset() {
      this.form1Params = {};
      this.params.pnum = 1;
      this.searchFuc();
    },
    search2(params) {
      this.form2Params = {
        areaCode: params.areaCode
          ? params.areaCode[params.areaCode.length - 1]
          : null,
        orderType: params.orderType,
        cropCode: params.cropCode,
        orderContent: params.orderContent,
        pnum: 1,
      };
      this.searchFuc2();
    },
    async searchFuc2() {
      this.params.pnum = 1;
      let res = await getOrderList({ ...this.params, ...this.form2Params });
      if (res.status) {
        this.tableData1 = res.data;
        this.total = res.dataNum;
      } else {
        this.$message.error("查询失败");
      }
    },
    reset2() {
      this.params.pnum = 1;
      this.form2 = {};
      this.searchFuc2();
    },
    async searchFuc() {
      let res = await getCompanyList({ ...this.params, ...this.form1Params });
      if (res.status) {
        this.tableData = res.data;
        this.total = res.dataNum;
      } else {
        this.$message.error("查询失败");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.land-trust {
  background: #fff;

  li {
    list-style: none;
  }
}

.quick-banner {
  position: relative;
  height: 700px;
  overflow: hidden;
}

.land-main {
  width: 1472px;
  margin: 0 auto;

  .s-bg {
    background: url("../../assets/icon-d-supply.png") no-repeat center center;
  }

  .new-list {
    padding: 30px 0;
    .news-tab {
      color: #999;
      font-size: 16px;
      display: flex;
      padding: 0px 0 20px 0px;
      .input-with-select {
        position: absolute;
        right: 0;
        top: -10px;
        width: auto;
      }

      li {
        padding: 8px 20px;
        font-size: 16px;
        font-weight: bold;
        background: #fff;
        margin-right: 12px;
        border: 2px solid #0668b3;
        cursor: pointer;
        color: #333;
        &:hover {
          color: #fff;
          background: #0668b3;
          border: 2px solid #0668b3;
        }

        &.active {
          color: #fff;
          background: #0668b3;
          border: 2px solid #0668b3;
        }
      }
    }

    .search {
      text-align: left;
      height: 60px;
    }

    /deep/.new-des {
      margin-top: 0;
    }
  }

  .pages {
    margin-top: 16px;
    text-align: right;
  }

  .news-des {
    margin-bottom: 20px;
  }

  .map-area {
    margin: 20px 0;
    height: 500px;
  }
}

.img {
  width: 400px;
}
</style>

<style lang="less" scoped>
.land-trust .el-table th {
  background: #0668b3;
  color: #fff;
}

.el-dialog {
  text-align: left;
}

.el-form-item {
  margin-bottom: 0px;
}
.pdfView {
  overflow: auto;
  height: 100%;
}
.content {
  width: 90%;
  min-width: 860px;
  margin: 10px auto;
  padding: 10px;
  background: rgba(85, 85, 85, 0.1);
  box-sizing: border-box;
  margin-top: 0;
}
/deep/.ht {
  margin-top: 10px !important;
  margin-bottom: 0 !important;
  box-sizing: border-box;
  height: calc(100% - 40px);
}
/deep/.el-dialog__body {
  height: calc(100% - 54px);
  padding-top: 0;
  overflow: auto;
  padding-bottom: 0;
  position: relative;
  .content {
    height: calc(100% - 128px);
  }
}
.pdf-page {
  border: 1px solid #8b8383;
  margin-bottom: 10px;
}
</style>
