<template>
  <div
    class="chat"
    v-loading="loading"
    style="z-index: 99999 !important"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <!-- 刚进页面发送请求时的弹窗 -->
    <!-- <el-button type="text" @click="isshow = true">点击打开 Dialog</el-button> -->

    <el-dialog
      title="提示"
      :visible.sync="isshow"
      width="50%"
      center
      style="z-index: 2000"
      :before-close="handleDialogClose"
    >
      <!-- 农民找托管 -->
      <div class="header" v-if="numdata == 1">
        <p>
          {{ Datalist1.areaName
          }}{{ Datalist1.serviceType == "1" ? "全程托管" : "环节托管" }}
          {{
            Datalist1.serviceArea
              ? Datalist1.serviceArea
              : 0 + "亩" + Datalist1.serviceScope
          }}
        </p>
        <p>
          <span class="kong">联系人：</span>
          {{ Datalist1.companyName }}
        </p>
      </div>

      <!-- 农民邀请托管 -->
      <div class="header" v-if="numdata == 2">
        <p>
          {{ Datalist1.areaName
          }}{{ Datalist1.orderType == "1" ? "全程托管" : "环节托管"
          }}{{ Datalist1.landArea }}亩{{ Datalist1.cropCode }}
        </p>
        <p>
          <span class="kong">联系人：</span>
          {{
            Datalist1.farmerName &&
            Datalist1.farmerName.substr(0, 1) +
              (Datalist1.sex == 2 ? "女士" : "先生")
          }}
        </p>
        <p><span>项目编号：</span>{{ Datalist1.orderCode }}</p>
      </div>

      <!-- 农服商找接管管 -->
      <div class="header" v-if="numdata == 3">
        <p>
          {{ Datalist1.areaName
          }}{{ Datalist1.orderType == "1" ? "全程托管" : "环节托管"
          }}{{ Datalist1.landArea }}亩{{ Datalist1.cropCode }}
        </p>
        <p>
          <span class="kong">联系人：</span>
          {{
            Datalist1.farmerName &&
            Datalist1.farmerName.substr(0, 1) +
              (Datalist1.sex == 2 ? "女士" : "先生")
          }}
        </p>
        <p><span>项目编号：</span>{{ Datalist1.orderCode }}</p>
      </div>

      <!-- 农服商邀请接管 -->
      <div class="header" v-if="numdata == 4">
        <p>
          {{ Datalist1.areaName
          }}{{ Datalist1.orderType == "1" ? "全程托管" : "环节托管"
          }}{{ Datalist1.landArea }}亩{{ Datalist1.cropCode }}
        </p>
        <p>
          <span class="kong">联系人：</span>
          {{
            Datalist1.farmerName &&
            Datalist1.farmerName.substr(0, 1) +
              (Datalist1.sex == 2 ? "女士" : "先生")
          }}
        </p>
        <p><span>项目编号：</span>{{ Datalist1.orderCode }}</p>
      </div>

      <!-- 在线竞价 -->
      <div class="header" v-if="numdata == 5">
        <p>
          {{ Datalist1.xmmc }}
        </p>
        <p>
          <span class="kong">转出方：</span>
          <span v-if="Datalist1.sex">
            {{
              Datalist1.crf &&
              Datalist1.crf.substr(0, 1) +
                (Datalist1.sex == 2 ? "女士" : "先生")
            }}
          </span>
          <span v-else>{{ Datalist1.crf }}</span>
        </p>
        <p><span>发布时间：</span>{{ Datalist1.fbsj }}</p>
      </div>

      <!-- <span>需要注意的是内容是默认不居中的</span> -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="quxiao">取 消</el-button>
        <!--  -->
        <!-- :disabled="dataList.length === 0" -->
        <el-button
          type="primary"
          :disabled="dataList.length === 0"
          @click="fasong(Datalist1, numdata, 9)"
          >发送</el-button
        >
        <el-button
          type="primary"
          :disabled="dataList.length === 0"
          v-if="numdata != 1"
          @click="yaoqing(Datalist1, numdata, 10, true)"
          >邀请签约</el-button
        >
      </span>
    </el-dialog>

    <!-- 分享弹窗 -->
    <el-dialog
      title="可发送项目"
      :visible.sync="centerDialogVisible"
      width="60%"
      center
    >
      <div class="dialog">
        <div class="header">
          <div
            :class="i == index ? 'active' : ''"
            v-for="(item, index) in dialogData"
            :key="index"
            @click="shareBtn(item, index)"
          >
            {{ item.title }}
          </div>
        </div>
        <!-- 详情项目 -->
        <div class="ItemList" v-for="(item, index) in UserList" :key="index">
          <div class="TypeStyle" v-if="item.receiverType == 2">
            <div class="timeBefore">
              <span>{{ item.dayNum }}</span>
              <p>{{ item.yearNum }} {{ item.monthNum }}</p>
            </div>
            <div class="detailsItem">
              <div class="ItemTitle">
                {{
                  item.areaName +
                  item.orderTypeName +
                  item.landArea +
                  "亩" +
                  item.cropCode
                }}
              </div>
              <div class="ItemLs">
                <span>项目编号：{{ item.orderCode }}</span>
                <span v-if="item.authId !== identityObj.identityId"
                  >信息来源：{{
                    item.farmerName &&
                    item.farmerName.substr(0, 1) +
                      (item.sex == 2 ? "女士" : "先生")
                  }}</span
                >
                <span v-else>{{ item.farmerName }}</span>
              </div>
            </div>
            <el-button type="primary" size="small" @click="shareSend(item)">
              发送
            </el-button>
            <el-button type="primary" size="small" @click="yaoqing(item, -1)">
              邀请签约
            </el-button>
          </div>
          <div class="TypeStyle" v-else-if="item.receiverType == 4">
            <div class="timeBefore">
              <span>{{ item.dayNum }}</span>
              <p>{{ item.yearNum }} {{ item.monthNum }}</p>
            </div>
            <div class="detailsItem">
              <div class="ItemTitle">
                {{
                  item.intentionRegion +
                  item.orderTypeName +
                  item.serviceArea +
                  "亩" +
                  item.serviceScope
                }}
              </div>
              <div class="ItemLs">
                <span>信息来源：{{ item.companyName }}</span>
              </div>
            </div>
            <el-button type="primary" size="small" @click="shareSend(item)">
              发送
            </el-button>
            <!-- <el-button type="primary" size="small" @click="yaoqing(item,true)">
              邀请托管
            </el-button> -->
          </div>
          <div class="TypeStyle" v-else-if="item.receiverType == 3">
            <div class="timeBefore">
              <span>{{ item.dayNum }}</span>
              <p>{{ item.yearNum }} {{ item.monthNum }}</p>
            </div>
            <div class="detailsItem">
              <div class="ItemTitle">
                {{ item.xmmc }}
              </div>
              <div class="ItemLs">
                <!-- {{ item }} -->
                <span v-if="item.currentIdentity == 2"
                  >信息来源：{{
                    item.crf &&
                    item.crf.substr(0, 1) + (item.sex == 2 ? "女士" : "先生")
                  }}</span
                >
                <span v-else>信息来源：{{ item.crf }}</span>
              </div>
            </div>
            <el-button type="primary" size="small" @click="shareSend(item, 9)">
              发送
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="yaoqing(item, 5, 10)"
            >
              邀请签约
            </el-button>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 托管弹窗 -->
    <leftDetail
      v-if="showDetail"
      @closeDialog1="closeDialog1"
      @endSearch="endSearch"
      @route="route"
      ref="showDetail"
    />
    <!-- 接管弹窗 -->
    <rightDetail
      v-if="showDetail2"
      @closeDialog2="closeDialog2"
      @route="route"
      @endSearch="endSearch"
      ref="showDetail2"
    />
    <!-- <znxx v-if="showDetail3" @closeDialog3="closeDialog3" ref="showDetail3" /> -->
    <div class="Left">
      <div class="title">沟通列表</div>
      <!-- 搜索好友 -->
      <div class="SearchChat">
        <div class="SearchInput">
          <el-input
            type="text"
            placeholder="请输入用户名搜索"
            v-model="input"
            @keyup.enter.native="endSearch"
          >
            <i slot="append" class="el-icon-search" @click="endSearch"></i>
          </el-input>
        </div>
      </div>
      <!-- 联系列表 -->
      <div class="ChatContent box1" v-if="dataList">
        <div
          class="ChatItem"
          v-for="(item, index) in dataList"
          :key="index"
          @click="ChangeTap(item, index)"
          :class="CommunicationIndex == index ? 'active' : ''"
        >
          <!-- :class="item.receiver == sendParams.receiver ? 'active' : ''" -->
          <div class="imgs">
            <i class="el-icon-user" v-if="CommunicationIndex != index"></i>
            <i class="el-icon-user-solid" v-else></i>
          </div>
          <div class="content">
            <div class="UserTop">
              <!-- <div class="user">站内信息</div> -->
              <div
                class="user"
                v-if="item.sendType == 2 || item.receiverType == 2"
              >
                {{
                  item.receiver &&
                  item.receiver.substr(0, 1) + (item.sex == 2 ? "女士" : "先生")
                }}
                <br />
                <span>
                  {{
                    item.phone
                      ? item.phone.slice(0, 3) + "****" + item.phone.slice(-4)
                      : ""
                  }}
                </span>
              </div>

              <div
                class="user"
                v-else-if="item.receiverType == 4 || item.receiverType == 3"
              >
                <!-- v-else-if="
                  item.receiver.length >= 4 ||
                  (item.receiverType == 4 && item.receiver == '系统消息')
                " -->
                {{ item.receiver }} <br />
                <span>
                  {{
                    item.phone
                      ? item.phone.slice(0, 3) + "****" + item.phone.slice(-4)
                      : ""
                  }}
                </span>
              </div>
              <div class="user" v-else>
                {{ item.receiver }}
              </div>

              <!-- <div  class="user" v-else>
                {{ item.receiver }}
                 <br> <span>{{item.phone.slice(0, 3)+ "****" +item.phone.slice(-4)}}</span>
              </div> -->
              <span style="float: right">{{
                item.messageList[0].sendTime
              }}</span>
            </div>
            <!-- -->
            <div
              class="ChatMessage"
              :style="{ color: '#303FAA', width: '230px' }"
            >
              {{ item.newContent }}
            </div>
          </div>
          <el-badge
            :value="item.unreadCount == 0 ? '' : item.unreadCount"
            class="item"
          >
          </el-badge>
        </div>
      </div>
      <div class="Loading" v-else>正在加载中.....</div>
    </div>
    <div class="Right">
      <div v-if="!dataList[CommunicationIndex]" class="loading">
        暂无数据.....
      </div>
      <div class="top" v-else>
        <!-- {{dataList[CommunicationIndex].receiver}} -->
        <div
          class="userName"
          v-if="
            dataList[CommunicationIndex].receiverType == 2 ||
            dataList[CommunicationIndex].sendType == 2
          "
        >
          {{
            dataList[CommunicationIndex].receiver &&
            dataList[CommunicationIndex].receiver.substr(0, 1) +
              (dataList[CommunicationIndex].sex == "2" ? "女士" : "先生")
          }}
        </div>
        <div class="userName" v-else>
          {{ dataList[CommunicationIndex].receiver }}
        </div>
        <!-- 系统消息 -->
        <!-- <div
          class="userName"
          v-else-if="dataList[CommunicationIndex].receiver == '系统消息'"
        >
          {{ dataList[CommunicationIndex].receiver }}
        </div> -->

        <div class="ChatContent" @scroll="handleScroll" ref="scroll">
          <!-- 判断消息类型添加样式 -->
          <!-- 消息加载 -->
          <div class="upLoadText" ref="upLoadText">向上滚动加载更多消息...</div>
          <div
            v-for="(item, index) in ChatList"
            :key="index"
            :class="
              item.messageType == 0
                ? 'zeroStyle'
                : item.messageType == '1'
                ? 'firstStyle'
                : item.messageType == '2'
                ? 'secondStyle'
                : item.messageType == '3'
                ? 'ThirdStyle'
                : item.messageType == '4'
                ? ' FourthStyle'
                : item.messageType == '5'
                ? 'FifthStyle'
                : item.messageType == '13'
                ?'zeroStyle'
                : 'Sixth'
            "
          >
            <div
              class="Item"
              :class="
                item.senderAuthId != identityObj.identityId
                  ? 'ChatLeft'
                  : 'ChatRight'
              "
            >
              <!-- 文件消息 -->
              <div
                class="chats"
                v-if="item.messageType == 6"
                style="width: 30% !important"
              >
                <div
                  class="BoxClass"
                  :class="
                    item.senderAuthId != identityObj.identityId
                      ? 'ChatLeft'
                      : 'ChatRight'
                  "
                >
                  <div class="user">
                    <span
                      v-if="item.receiverType == 2"
                      :style="{ color: '#303FAA', fontSize: 15 + 'px' }"
                      >{{
                        item.authName &&
                        item.authName.substr(0, 1) +
                          (item.sex == "2" ? "女士" : "先生")
                      }}
                      ：
                    </span>
                    <span
                      v-else
                      :style="{ color: '#303FAA', fontSize: 15 + 'px' }"
                      >{{ item.authName }} ：
                    </span>
                    <span>{{ item.sendTime }}</span>
                  </div>
                  <div class="Download" @click="download(item)">
                    <p>
                      <span>
                        {{ JSON.parse(item.content).sourceFileName }}</span
                      >
                      <i class="el-icon-download" title="下载"></i>
                    </p>
                  </div>
                </div>
              </div>

              <!-- 会话托管 -->
              <!-- <div class="chats" v-if="item.messageType == 5">
                <el-card>
                  <div
                    v-if="item.content"
                    class="content"
                    style="
                      display: flex;
                      justify-content: space-between;
                      border: 0px !important;
                    "
                  >
                    <div
                      class="header"
                      v-if="!JSON.parse(item.content).companyName"
                    >
                      <p>
                        {{ JSON.parse(item.content).areaName
                        }}{{
                          JSON.parse(item.content).orderType == "1"
                            ? "全程托管"
                            : "环节托管"
                        }}{{ JSON.parse(item.content).landArea }}亩{{
                          JSON.parse(item.content).cropCode
                        }}
                      </p>
                      <p>
                        <span class="kong">联系人：</span>
                        {{ JSON.parse(item.content).farmerName }}
                      </p>
                      <p>
                        <span>项目编号：</span
                        >{{ JSON.parse(item.content).orderCode }}
                      </p>
                    </div>

                    <div
                      class="header"
                      v-if="JSON.parse(item.content).companyName"
                    >
                      <p>
                        {{ JSON.parse(item.content).address
                        }}{{
                          JSON.parse(item.content).serviceType == "1"
                            ? "全程托管"
                            : "环节托管"
                        }}{{ JSON.parse(item.content).serviceArea }}亩{{
                          JSON.parse(item.content).serviceScope
                        }}
                      </p>
                      <p>
                        <span>联系人：</span>
                        {{ JSON.parse(item.content).companyName }}
                      </p>
                    </div>


                    <div
                      class="rightDetail"
                      style="align-content: center"
                      v-if="!JSON.parse(item.content).companyName"
                    >
                      <el-button
                        type="primary"
                        size="small "
                        @click="fasong(item, false)"
                        >发送</el-button
                      >
                      <el-button
                        type="primary"
                        size="small "
                        @click="yaoqing(item, false)"
                        >邀请托管</el-button
                      >
                    </div>

                    <div
                      class="rightDetail"
                      style="align-content: center"
                      v-if="JSON.parse(item.content).companyName"
                    >
                      <el-button
                        type="primary"
                        size="small "
                        @click="fasong(item, true)"
                        >发送</el-button
                      >
                    </div>
                  </div>
                </el-card>
              </div> -->

              <!-- 会话接管 -->
              <!-- <div class="chats" v-else-if="item.messageType == 4">
                <el-card>
                  <div
                    v-if="item.content"
                    class="content"
                    style="
                      display: flex;
                      justify-content: space-between;
                      border: 0px;
                    "
                  >
                    <div class="header">
                      <p>
                        {{ JSON.parse(item.content).areaName
                        }}{{
                          JSON.parse(item.content).orderType == "1"
                            ? "全程托管"
                            : "环节托管"
                        }}{{ JSON.parse(item.content).landArea }}亩{{
                          JSON.parse(item.content).cropCode
                        }}
                      </p>
                      <p>
                        <span class="kong">联系人：</span>
                        {{
                          JSON.parse(item.content).farmerName.substr(0, 1) +
                          (JSON.parse(item.content).sex == 2 ? "女士" : "先生")
                        }}
                      </p>
                      <p>
                        <span>项目编号：</span
                        >{{ JSON.parse(item.content).orderCode }}
                      </p>
                    </div>

                    <div style="align-content: center">
                      <el-button
                        type="primary"
                        size="small "
                        @click="fasong(item)"
                        >发送</el-button
                      >
                      <el-button
                        type="primary"
                        size="small "
                        @click="yaoqing(item, false)"
                        >邀请托管</el-button
                      >
                    </div>
                  </div>
                </el-card>
              </div> -->

              <!-- 分享接管 -->
              <div class="chats" v-else-if="item.messageType == 3">
                <div class="user">
                  <span
                    v-if="item.receiverType == 2"
                    :style="{ color: '#303FAA', fontSize: 15 + 'px' }"
                    >{{
                      item.authName &&
                      item.authName.substr(0, 1) +
                        (item.sex == "2" ? "女士" : "先生")
                    }}</span
                  >
                  <span
                    v-else
                    :style="{ color: '#303FAA', fontSize: 15 + 'px' }"
                    >{{ item.authName }} ：</span
                  >
                  <span>{{ item.sendTime }}</span>
                </div>
                <el-card>
                  <!-- <div class="context"> -->
                  <div v-if="item.content" class="content">
                    <p>
                      {{ JSON.parse(item.content).intentionRegion
                      }}{{
                        JSON.parse(item.content).serviceType == "1"
                          ? "全程托管"
                          : "环节托管"
                      }}
                      {{
                        (JSON.parse(item.content).serviceArea !== null
                          ? JSON.parse(item.content).serviceArea
                          : 0) +
                        "亩" +
                        JSON.parse(item.content).serviceScope
                      }}
                    </p>
                    <p>
                      <span>创建时间：</span
                      >{{ JSON.parse(item.content).createTime }}
                    </p>
                    <p>
                      <!-- {{JSON.parse(item.content).contactMan}} -->
                      <span
                        >联系人：{{
                          JSON.parse(item.content).contactMan &&
                          JSON.parse(item.content).contactMan.substr(0, 1) +
                            (JSON.parse(item.content).sex == 2
                              ? "女士"
                              : "先生")
                        }}</span
                      >
                      <!-- {{
                        JSON.parse(item.content).contactMan.substr(0, 1) +
                        (JSON.parse(item.content).sex == "2" ? "女士" : "先生")
                      }} -->
                    </p>
                    <!-- <p v-else>
                      <span>联系人：</span
                      >{{ JSON.parse(item.content).contactMan }}
                    </p> -->
                  </div>
                  <!-- 查看按钮 -->
                  <div class="rightDetail">
                    <el-button
                      type="primary"
                      size="medium "
                      @click="detailClick(JSON.parse(item.content))"
                      >查看</el-button
                    >
                  </div>
                  <!-- </div> -->
                </el-card>
              </div>
              <!-- 分享托管 -->
              <div class="chats" v-else-if="item.messageType == 2">
                <div class="user">
                  <span
                    v-if="item.receiverType == 2"
                    :style="{ color: '#303FAA', fontSize: 15 + 'px' }"
                    >{{
                      item.authName &&
                      item.authName.substr(0, 1) +
                        (item.sex == "2" ? "女士" : "先生")
                    }}
                    ：
                  </span>
                  <span
                    v-else
                    :style="{ color: '#303FAA', fontSize: 15 + 'px' }"
                    >{{ item.authName }} ：
                  </span>
                  <span>{{ item.sendTime }}</span>
                </div>
                <el-card>
                  <div class="context" style="">
                    <div v-if="item.content" class="content">
                      <p>
                        {{ JSON.parse(item.content).areaName
                        }}{{
                          JSON.parse(item.content).orderType == "1"
                            ? "全程托管"
                            : "环节托管"
                        }}{{
                          (JSON.parse(item.content).landArea !== null
                            ? JSON.parse(item.content).landArea
                            : 0) +
                          "亩" +
                          JSON.parse(item.content).cropCode
                        }}
                      </p>
                      <p>
                        <span>创建时间：</span>
                        {{ JSON.parse(item.content).createTime }}
                      </p>
                      <p>
                        <span>联系人：</span
                        >{{
                          JSON.parse(item.content).farmerName &&
                          JSON.parse(item.content).farmerName.substr(0, 1) +
                            (JSON.parse(item.content).sex == 2
                              ? "女士"
                              : "先生")
                        }}
                      </p>
                    </div>
                    <!-- 查看按钮 -->
                    <div class="findBtn">
                      <el-button
                        type="primary"
                        size="medium"
                        @click="detailClick2(JSON.parse(item.content))"
                        >查看</el-button
                      >
                    </div>
                  </div>
                </el-card>
              </div>
              <!-- 图片消息 -->
              <div class="chats" v-else-if="item.messageType == 1">
                <div class="BoxClass" style="margin-top: 10px">
                  <div class="user">
                    <span
                      v-if="item.receiverType == 2"
                      :style="{ color: '#303FAA', fontSize: 15 + 'px' }"
                      >{{
                        item.authName &&
                        item.authName.substr(0, 1) +
                          (item.sex == "2" ? "女士" : "先生")
                      }}
                      ：
                    </span>
                    <span
                      v-else
                      :style="{ color: '#303FAA', fontSize: 15 + 'px' }"
                      >{{ item.authName }} ：
                    </span>
                    <span>{{ item.sendTime }}</span>
                  </div>
                  <div class="img">
                    <el-image
                      style="width: auto; height: auto"
                      :src="JSON.parse(item.content).imgUrl"
                      :preview-src-list="[JSON.parse(item.content).imgUrl]"
                    >
                    </el-image>
                  </div>
                </div>
              </div>
              <!-- 交换电话消息 -->
              <div class="chats" v-else-if="item.messageType == 7">
                <div
                  :class="
                    item.senderAuthId != identityObj.identityId
                      ? 'box-card ChatLeft '
                      : 'box-card ChatRight'
                  "
                >
                  <div class="user">
                    <span
                      v-if="item.receiverType == 2"
                      :style="{ color: '#303FAA', fontSize: 15 + 'px' }"
                      >{{
                        item.authName &&
                        item.authName.substr(0, 1) +
                          (item.sex == "2" ? "女士" : "先生")
                      }}
                      ：
                    </span>
                    <span
                      v-else
                      :style="{ color: '#303FAA', fontSize: 15 + 'px' }"
                      >{{ item.authName }} ：
                    </span>
                    <span>{{ item.sendTime }}</span>
                  </div>
                  <el-card class="box-card" style="width: 400px">
                    <div class="card-content">
                      <div class="request-text" v-if="item.status == '3'">
                        <!-- {{ dianhua.msg }} -->
                        {{
                          item.senderAuthId != identityObj.identityId &&
                          item.receiverType
                            ? "已同意请求"
                            : "交换成功"
                        }}
                      </div>
                      <div class="request-text" v-else-if="item.status == '2'">
                        {{
                          item.senderAuthId !== identityObj.identityId &&
                          item.receiverType
                            ? "你已拒绝了对方的请求"
                            : "对方已拒绝你的请求"
                        }}
                      </div>
                      <div class="request-text" v-else>请求交换联系方式</div>
                      <div
                        class="button-group"
                        v-if="item.senderAuthId != identityObj.identityId"
                      >
                        <!-- 1111 -->
                        <div
                          v-if="
                            item.status != '2' &&
                            item.status != '3' &&
                            item.status
                          "
                        >
                          <el-button
                            type="danger"
                            class="reject-button"
                            @click="Refuse(item, 7)"
                            >拒绝</el-button
                          >

                          <el-button
                            type="success"
                            class="accept-button"
                            @click="agree(item, 7)"
                            >同意</el-button
                          >
                        </div>
                      </div>
                    </div>
                  </el-card>
                </div>
              </div>

              <!-- 是否同意托管 -->
              <div class="chats" v-else-if="item.messageType == 8">
                <div
                  :class="
                    item.senderAuthId != identityObj.identityId
                      ? ' ChatLeft '
                      : 'ChatRight'
                  "
                >
                  <div class="user">
                    <span
                      v-if="item.receiverType == 2"
                      :style="{ color: '#303FAA', fontSize: 15 + 'px' }"
                      >{{
                        item.authName &&
                        item.authName.substr(0, 1) +
                          (item.sex == "2" ? "女士" : "先生")
                      }}
                      ：
                    </span>
                    <span
                      v-else
                      :style="{ color: '#303FAA', fontSize: 15 + 'px' }"
                      >{{ item.authName }} ：
                    </span>
                    <span>{{ item.sendTime }}</span>
                  </div>
                  <el-card>
                    <div
                      class="context"
                      style="display: flex; justify-content: space-between"
                    >
                      <div v-if="item.content" class="content">
                        <p style="font-size: 20px; padding: 0 !important">
                          邀请对方托管:
                        </p>
                        <p>
                          {{ JSON.parse(item.content).areaName
                          }}{{
                            JSON.parse(item.content).orderType == "1"
                              ? "全程托管"
                              : "环节托管"
                          }}{{
                            JSON.parse(item.content).landArea +
                            "亩" +
                            JSON.parse(item.content).cropCode
                          }}
                        </p>
                        <p>
                          <span>创建时间：</span>
                          {{ JSON.parse(item.content).createTime }}
                        </p>
                        <p>
                          <span>联系人：</span
                          >{{
                            JSON.parse(item.content).farmerName &&
                            JSON.parse(item.content).farmerName.substr(0, 1) +
                              (JSON.parse(item.content).sex == 2
                                ? "女士"
                                : "先生")
                          }}
                        </p>
                      </div>
                      <!-- 查看按钮 -->
                      <div class="rightDetail" style="align-content: center">
                        <el-button
                          type="primary"
                          size="medium  "
                          @click="detailClick2(JSON.parse(item.content))"
                          >查看</el-button
                        >
                      </div>
                    </div>
                    <!-- 邀请托管  同意 拒绝 -->
                    <div
                      class="button-group"
                      v-if="item.senderAuthId != identityObj.identityId"
                    >
                      <!-- {{item.messageId}} -->
                      <div
                        v-if="
                          item.status != '2' &&
                          item.status != '3' &&
                          item.status
                        "
                      >
                        <div>
                          <el-button
                            type="danger"
                            class="reject-button"
                            @click="jj(item, 2)"
                            >拒绝</el-button
                          >

                          <el-button
                            type="success"
                            class="accept-button"
                            @click="ty(item, 3)"
                            >同意</el-button
                          >
                        </div>
                      </div>
                    </div>
                    <div class="request-text" v-if="item.status == '3'">
                      已同意
                    </div>
                    <div class="request-text" v-else-if="item.status == '2'">
                      已拒绝该请求
                    </div>
                    <!-- <div
                        class="request-text"
                        v-if="item.status !== '2' && item.status !== '3'"
                      >
                        请求交换联系方式
                      </div> -->
                  </el-card>
                </div>
              </div>

              <!-- 分享竞价 -->
              <div class="chats" v-else-if="item.messageType == 9">
                <div class="user">
                  <span
                    v-if="item.receiverType == 2"
                    :style="{ color: '#303FAA', fontSize: 15 + 'px' }"
                    >{{
                      item.authName &&
                      item.authName.substr(0, 1) +
                        (item.sex == "2" ? "女士" : "先生")
                    }}</span
                  >
                  <span
                    v-else
                    :style="{ color: '#303FAA', fontSize: 15 + 'px' }"
                    >{{ item.authName }} :</span
                  >
                  <span>{{ item.sendTime }}</span>
                </div>
                <el-card>
                  <!-- <div class="context"> -->
                  <div v-if="item.content" class="content">
                    <span>{{
                      JSON.parse(item.content).xmmc
                        ? JSON.parse(item.content).xmmc
                        : ""
                    }}</span>

                    <p>
                      交易方式：{{
                        JSON.parse(item.content).jyfs
                          ? JSON.parse(item.content).jyfs
                          : "无"
                      }}
                    </p>
                    <p>
                      流转年限：{{
                        JSON.parse(item.content).lznx
                          ? JSON.parse(item.content).lznx + "年"
                          : ""
                      }}
                    </p>
                    <p v-if="JSON.parse(item.content).xmdz">
                      详细地址：{{ JSON.parse(item.content).xmdz }}
                    </p>
                    <p>
                      发布时间：{{
                        JSON.parse(item.content).fbsj
                          ? JSON.parse(item.content).fbsj
                          : ""
                      }}
                    </p>
                  </div>
                  <!-- 查看按钮 -->
                  <div class="rightDetail">
                    <el-button
                      type="primary"
                      style="width: 100%"
                      @click="tiaozhuan(JSON.parse(item.content))"
                      >查看</el-button
                    >
                  </div>
                  <!-- </div> -->
                </el-card>
              </div>

              <!-- 邀请竞价 -->
              <div class="chats" v-else-if="item.messageType == 10">
                <div
                  :class="
                    item.senderAuthId != identityObj.identityId
                      ? ' ChatLeft '
                      : 'ChatRight'
                  "
                >
                  <div class="user">
                    <span
                      v-if="item.receiverType == 2"
                      :style="{ color: '#303FAA', fontSize: 15 + 'px' }"
                      >{{
                        item.authName &&
                        item.authName.substr(0, 1) +
                          (item.sex == "2" ? "女士" : "先生")
                      }}
                      ：
                    </span>
                    <span
                      v-else
                      :style="{ color: '#303FAA', fontSize: 15 + 'px' }"
                      >{{ item.authName }} ：
                    </span>
                    <span>{{ item.sendTime }}</span>
                  </div>
                  <el-card>
                    <div
                      class="context"
                      style="display: flex; justify-content: space-between"
                    >
                      <div v-if="item.content" class="content">
                        <p style="font-size: 20px; padding: 0 !important">
                          邀请就如下项目签约:
                        </p>

                        <span>{{
                          JSON.parse(item.content).xmmc
                            ? JSON.parse(item.content).xmmc
                            : ""
                        }}</span>
                        <p>
                          交易方式：{{
                            JSON.parse(item.content).jyfs
                              ? JSON.parse(item.content).jyfs
                              : "无"
                          }}
                        </p>
                        <p>
                          流转年限：{{
                            JSON.parse(item.content).lznx
                              ? JSON.parse(item.content).lznx + "年"
                              : ""
                          }}
                        </p>
                        <p v-if="JSON.parse(item.content).xmdz">
                          详细地址：{{ JSON.parse(item.content).xmdz }}
                        </p>
                        <p>
                          发布时间：{{
                            JSON.parse(item.content).fbsj
                              ? JSON.parse(item.content).fbsj
                              : ""
                          }}
                        </p>
                      </div>
                      <!-- 查看按钮 -->
                      <div class="rightDetail" style="align-content: center">
                        <el-button
                          type="primary"
                          size="medium  "
                          @click="tiaozhuan(JSON.parse(item.content))"
                          >查看</el-button
                        >
                      </div>
                    </div>
                    <!-- 邀请托管  同意 拒绝 -->
                    <div
                      class="button-group"
                      v-if="item.senderAuthId != identityObj.identityId"
                    >
                      <!-- {{item.messageId}} -->
                      <div
                        v-if="
                          item.status != '2' &&
                          item.status != '3' &&
                          item.status
                        "
                      >
                        <div>
                          <el-button
                            type="danger"
                            class="reject-button"
                            @click="jj(item, 2)"
                            >拒绝</el-button
                          >

                          <el-button
                            type="success"
                            class="accept-button"
                            @click="ty(item, 3)"
                            >同意</el-button
                          >
                        </div>
                      </div>
                    </div>
                    <div class="request-text" v-if="item.status == '3'">
                      已同意
                    </div>
                    <div class="request-text" v-else-if="item.status == '2'">
                      已拒绝该请求
                    </div>
                  </el-card>
                </div>
              </div>

              <!-- 邀请查看 -->
              <div class="chats" v-else-if="item.messageType == 11">
                <div
                  :class="
                    item.senderAuthId != identityObj.identityId
                      ? ' ChatLeft '
                      : 'ChatRight'
                  "
                >
                  <div class="user">
                    <span
                      v-if="item.receiverType == 2"
                      :style="{ color: '#303FAA', fontSize: 15 + 'px' }"
                      >{{
                        item.authName &&
                        item.authName.substr(0, 1) +
                          (item.sex == "2" ? "女士" : "先生")
                      }}
                      ：
                    </span>
                    <span
                      v-else
                      :style="{ color: '#303FAA', fontSize: 15 + 'px' }"
                      >{{ item.authName }} ：
                    </span>
                    <span>{{ item.sendTime }}</span>
                  </div>
                  <el-card>
                    <div
                      class="context"
                      style="display: flex; justify-content: space-between"
                    >
                      <div v-if="item.content" class="content">
                        <p style="font-size: 20px; padding: 0 !important">
                          申请查看证书:
                        </p>
                        <span
                          >{{
                            JSON.parse(item.content).xmmc
                              ? JSON.parse(item.content).xmmc
                              : ""
                          }}的产权凭证</span
                        >
                        <!-- <p>
                          交易方式：{{
                            JSON.parse(item.content).jyfs
                              ? JSON.parse(item.content).jyfs
                              : "无"
                          }}
                        </p>
                        <p>
                          流转年限：{{
                            JSON.parse(item.content).lznx
                              ? JSON.parse(item.content).lznx + "年"
                              : ""
                          }}
                        </p> -->
                        <!-- <p v-if="JSON.parse(item.content).xmdz">
                          详细地址：{{ JSON.parse(item.content).xmdz }}
                        </p>
                        <p>
                          发布时间：{{
                            JSON.parse(item.content).fbsj
                              ? JSON.parse(item.content).fbsj
                              : ""
                          }}
                        </p> -->
                      </div>
                      <!-- 查看按钮 -->
                      <div class="rightDetail" style="align-content: center">
                        <el-button
                          type="primary"
                          size="medium  "
                          @click="tiaozhuan(JSON.parse(item.content))"
                          >查看</el-button
                        >
                      </div>
                    </div>
                    <!-- 邀请托管  同意 拒绝 -->
                    <div
                      class="button-group"
                      v-if="item.senderAuthId != identityObj.identityId"
                    >
                      <!-- {{item.messageId}} -->
                      <div
                        v-if="
                          item.status != '2' &&
                          item.status != '3' &&
                          item.status
                        "
                      >
                        <div>
                          <el-button
                            type="danger"
                            class="reject-button"
                            @click="Refuse(item, 11)"
                            >拒绝</el-button
                          >

                          <el-button
                            type="success"
                            class="accept-button"
                            @click="agree(item, 11)"
                            >同意</el-button
                          >
                        </div>
                      </div>
                    </div>
                    <div class="request-text" v-if="item.status == '3'">
                      已同意
                    </div>
                    <div class="request-text" v-else-if="item.status == '2'">
                      已拒绝该请求
                    </div>
                  </el-card>
                </div>
              </div>

              <!-- 邀请查看 -->
              <div class="chats" v-else-if="item.messageType == 12">
                <div
                  :class="
                    item.senderAuthId != identityObj.identityId
                      ? ' ChatLeft '
                      : 'ChatRight'
                  "
                >
                  <div class="user">
                    <span
                      v-if="item.receiverType == 2"
                      :style="{ color: '#303FAA', fontSize: 15 + 'px' }"
                      >{{
                        item.authName &&
                        item.authName.substr(0, 1) +
                          (item.sex == "2" ? "女士" : "先生")
                      }}
                      ：
                    </span>
                    <span
                      v-else
                      :style="{ color: '#303FAA', fontSize: 15 + 'px' }"
                      >{{ item.authName }} ：
                    </span>
                    <span>{{ item.sendTime }}</span>
                  </div>
                  <el-card>
                    <div
                      class="context"
                      style="display: flex; justify-content: space-between"
                    >
                      <div v-if="item.content" class="content">
                        <p style="font-size: 20px; padding: 0 !important">
                          申请查看再流转证明
                        </p>
                        <!-- <span
                          >{{
                            JSON.parse(item.content).xmmc
                              ? JSON.parse(item.content).xmmc
                              : ""
                          }}的产权凭证</span
                        > -->
                        <!-- <p>
                          交易方式：{{
                            JSON.parse(item.content).jyfs
                              ? JSON.parse(item.content).jyfs
                              : "无"
                          }}
                        </p>
                        <p>
                          流转年限：{{
                            JSON.parse(item.content).lznx
                              ? JSON.parse(item.content).lznx + "年"
                              : ""
                          }}
                        </p> -->
                        <!-- <p v-if="JSON.parse(item.content).xmdz">
                          详细地址：{{ JSON.parse(item.content).xmdz }}
                        </p>
                        <p>
                          发布时间：{{
                            JSON.parse(item.content).fbsj
                              ? JSON.parse(item.content).fbsj
                              : ""
                          }}
                        </p> -->
                      </div>
                      <!-- 查看按钮 -->
                      <div class="rightDetail" style="align-content: center">
                        <el-button
                          type="primary"
                          size="medium  "
                          @click="tiaozhuan(JSON.parse(item.content))"
                          >查看</el-button
                        >
                      </div>
                    </div>
                    <!-- 邀请托管  同意 拒绝 -->
                    <div
                      class="button-group"
                      v-if="item.senderAuthId != identityObj.identityId"
                    >
                      <!-- {{item.messageId}} -->
                      <div
                        v-if="
                          item.status != '2' &&
                          item.status != '3' &&
                          item.status
                        "
                      >
                        <div>
                          <el-button
                            type="danger"
                            class="reject-button"
                            @click="Refuse(item, 12)"
                            >拒绝</el-button
                          >

                          <el-button
                            type="success"
                            class="accept-button"
                            @click="agree(item, 12)"
                            >同意</el-button
                          >
                        </div>
                      </div>
                    </div>
                    <div class="request-text" v-if="item.status == '3'">
                      已同意
                    </div>
                    <div class="request-text" v-else-if="item.status == '2'">
                      已拒绝该请求
                    </div>
                  </el-card>
                </div>
              </div>

              <!-- 站内消息 -->

              <!-- 文字消息 -->
              <div class="chats" v-else-if="item.messageType == 13">
                <div
                  class="allStyle"
                  :class="
                    item.senderAuthId != identityObj.identityId
                      ? 'ChatLeft'
                      : 'ChatRight'
                  "
                >
                  <div class="user">
                    <span
                      v-if="item.receiverType == 2"
                      :style="{ color: '#303FAA', fontSize: 15 + 'px' }"
                    >
                      {{
                        item.authName &&
                        item.authName.substr(0, 1) +
                          (item.sex == "2" ? "女士" : "先生")
                      }}
                      ：</span
                    >
                    <span
                      v-else-if="
                        dataList[CommunicationIndex].receiver == '系统消息'
                      "
                      :style="{ color: '#303FAA', fontSize: 15 + 'px' }"
                      >{{ item.authName }} ：</span
                    >
                    <span
                      v-else
                      :style="{ color: '#303FAA', fontSize: 15 + 'px' }"
                      >{{ item.authName }} ：</span
                    >
                    <span>{{ item.sendTime }} </span>
                  </div>
                  <div v-if="item.content" class="content" >
                    <span >
                      {{ item.content }}
                      <div style="text-align: center; margin-top: 5px;">
                        <el-button type="success" @click="renling()">认领</el-button>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
              <!-- 文字消息 -->
              <div class="chats" v-else-if="item.messageType == 0">
                <div
                  class="allStyle"
                  :class="
                    item.senderAuthId != identityObj.identityId
                      ? 'ChatLeft'
                      : 'ChatRight'
                  "
                >
                  <div class="user">
                    <span
                      v-if="item.receiverType == 2"
                      :style="{ color: '#303FAA', fontSize: 15 + 'px' }"
                    >
                      {{
                        item.authName &&
                        item.authName.substr(0, 1) +
                          (item.sex == "2" ? "女士" : "先生")
                      }}
                      ：</span
                    >
                    <span
                      v-else-if="
                        dataList[CommunicationIndex].receiver == '系统消息'
                      "
                      :style="{ color: '#303FAA', fontSize: 15 + 'px' }"
                      >{{ item.authName }} ：</span
                    >
                    <span
                      v-else
                      :style="{ color: '#303FAA', fontSize: 15 + 'px' }"
                      >{{ item.authName }} ：</span
                    >
                    <span>{{ item.sendTime }} </span>
                  </div>
                  <div v-if="item.content" class="content">
                    <span>
                      {{ item.content }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bottom">
        <div class="UploadOrShare">
          <ul>
            <!-- 图片上传 -->
            <li>
              <el-upload
                class="upload-demo"
                :action="uploadUrl"
                name="files"
                :auto-upload="false"
                :on-change="handleChange"
                :file-list="fileList"
                :show-file-list="false"
              >
                <el-button
                  type="primary"
                  size="medium  "
                  class="iconfont icon-shangchuantupian"
                ></el-button>
                <!-- <img src="../../assets/icon/UploadImg.png" alt=""> -->
              </el-upload>
            </li>
            <!-- 分享 -->
            <li @click="ShareList()">
              <el-button
                type="primary"
                size="medium"
                class="iconfont icon-fenxiang"
              ></el-button>
              <!-- <el-button type="primary" size="medium  " icon="el-icon-share"></el-button> -->
              <!-- <img src="../../assets/icon/Share.png" alt=""> -->
            </li>
            <!-- 文件上传 -->
            <li>
              <el-upload
                class="upload-demo"
                accept="|.doc,.docx,.xls,.xlsx,.pdf,.zip,.7z"
                :action="uploadUrl"
                name="files"
                :auto-upload="false"
                :on-change="handleChange2"
                :file-list="fileList"
                :show-file-list="false"
              >
                <el-button
                  type="primary"
                  size="medium  "
                  class="iconfont icon-wenjianshangchuan"
                ></el-button>
                <!-- <img src="../../assets/icon/FileUpload.png" alt=""> -->
              </el-upload>
            </li>
            <!-- 交换电话 -->
            <li
              v-if="
                dataList[CommunicationIndex] &&
                !dataList[CommunicationIndex].change
              "
            >
              <el-button
                type="primary"
                size="medium "
                class="iconfont icon-zhongbo"
                @click="exchange"
              ></el-button>
            </li>
            <li
              v-if="
                dataList[CommunicationIndex] &&
                dataList[CommunicationIndex].change
              "
            >
              <el-tooltip
                type="primary"
                size="medium"
                :content="dataList[CommunicationIndex].phone"
                placement="top-start"
              >
                <el-button>查看电话</el-button>
              </el-tooltip>
            </li>
          </ul>
        </div>

        <!-- 输入框 -->
        <div class="sendChat">
          <div class="ipt">
            <el-input
              type="textarea"
              placeholder="请输入内容"
              v-model="ChatMessage"
              maxlength="100"
              rows="3"
              resize="none"
              :autosize="{ minRows: 2, maxRows: 4 }"
              @keyup.enter.native="addBtn"
            >
            </el-input>
          </div>
          <div class="BtnChat">
            <el-button type="primary" @click="addBtn">发送</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  SearchItem,
  DialogueContent,
  SendMessage,
  FarmerSharing,
  UnreadMessage,
  exchangeTelephone,
  Hosting,
  jingjia,
  Agree,
  agreeRecirculatio,
} from "../../api/websocket";
import { uploadFile } from "@/api/fileApi";
import { mapGetters, mapMutations } from "vuex";
import { getContent } from "./index";
import leftDetail from "./SupplyTgLeftDetail.vue";
import rightDetail from "./SupplyTgRightDetail.vue";
import znxx from "./txt.vue";
//数据总线
import { Utils } from "@/assets/js/util.js";
import { telephoneValidator } from "../../js/utils/rules";

export default {
  components: {
    leftDetail,
    rightDetail,
    znxx,
  },
  data() {
    return {
      // sts: true,
      isshow: false, // 控制发送邀请的开关
      Datalist1: [], // 发送邀请的数据
      numdata: 1, //看传的是什么
      dianhua: [], // 电话
      // exchangebool: false, // 看看是否已经同意了
      // index:0,
      col: "#303FAA",
      uploadUrl: `${window.globalUrl.HOME_API_WEI}/admin/file/upload`,
      size: 20,
      input: "",
      ChatMessage: "",
      centerDialogVisible: false,
      msg: "",
      dialogData: [], //沟通人双方列表
      i: 0,
      inputSearch: "",
      dataList: [], //左侧沟通列表
      UserList: [], //分享项目里的项目
      fileList: [], //上传图片
      CommunicationIndex: 0, // 左侧tab index
      openid: "", // 在线沟通
      // webSocketObject: null,
      // 心跳检测
      websock: null, //建立的连接
      lockReconnect: false, //是否真正建立连接
      timeout: 28 * 1000, //30秒一次心跳
      timeoutObj: null, //心跳倒计时
      serverTimeoutObj: null, //心跳倒计时
      timeoutnum: null, //断开 重连倒计时
      sendParams: "",
      AllMessages: 0,
      ChatList: [], //右侧沟通列表
      loading: false, // 加载状态
      requestMoreMessage: true, //是否请求更多信息
      page: 1, // 当前页数,
      baseUrl: window.globalUrl.BASE_API,
      //弹窗
      showDetail: false,
      showDetail2: false,
      // showDetail3: false,
      shareItem: [], // 初始化弹窗
      loading: false,
    };
  },
  created() {
    //左侧列表初始化
    this.endSearch();
    this.UnMessage();
    this.toDialogData();
    //Header页面调动的方法
    var that = this;
    Utils.$on("updateChat", function (e) {
      // console.log(e);
      that.updateChat(e);
    });
    Utils.$on("updatelist", function (e) {
      // console.log(e);
      that.updatelist(e);
    });
    Utils.$on("upisChange", function (e) {
      // console.log(e);
      that.upisChange(e);
    });
  },

  mounted() {
    // this.endSearch(); //初始化左侧列表
    this.route(); // 接收路由传参
  },
  computed: {
    ...mapGetters(["identityObj"]),
  },
  // 在失去活力时将对象转换为 JSON 字符串并保存到 localStorage

  methods: {
    renling(){
      this.$router.replace({ path: "/home/RenZheng" }); // 重定向到根路径
    },
    handlePageRefresh(event) {
      // 在页面刷新前执行的操作
      console.log("页面即将刷新");

      // 执行其他逻辑，例如清空数据等
      this.Datalist1 = [];

      // 最后可以选择是否显示确认提示
      event.returnValue = ""; // 页面刷新时可以显示确认提示
    },
    // 路由传参
    route(val) {
      // console.log("router", val);
      this.CommunicationIndex = 0;

      if (!val) {
        // 获取传递过来的参数
        const isshow = Boolean(this.$route.query.isshow);
        const data = this.$route.query.data;
        const num = this.$route.query.num;
        // 现在你可以在这里使用 isshow 参数了
        console.log("接收到的参数 isshow:111", isshow, "data", data);
        // console.log(JSON.stringify(data));
        this.isshow = isshow;
        this.Datalist1 = data;
        this.numdata = num;
        console.log(this.Datalist1);
        if (
          !(
            this.Datalist1 &&
            (this.Datalist1.authId || this.Datalist1.identityId)
          )
        ) {
          this.isshow = false;
          this.Datalist1 = [];
          this.$router.replace({ path: "/home/Chat" }); // 重定向到根路径
          console.log("****************************");
        }
      } else {
        const isshow = val.isshow;
        const data = val.data;
        const num = val.num;
        // 现在你可以在这里使用 isshow 参数了
        console.log("接收到的参数 isshow:222", isshow, "data", data);
        this.isshow = isshow;
        this.Datalist1 = data;
        this.numdata = num;
        console.log(this.Datalist1);

        if (
          !(
            this.Datalist1 &&
            (this.Datalist1.authId || this.Datalist1.identityId)
          )
        ) {
          this.isshow = false;
          this.Datalist1 = [];
          this.$router.replace({ path: "/home/Chat" }); // 重定向到根路径
          console.log("****************************");
        }
      }

      // console.log(11111111111111111111111111111111111111111111111111111111111);
    },
    ...mapMutations(["SET_MESSAGE"]),
    //连接打开
    webSocketOnOpen(e) {
      console.log("与服务端连接打开->", e);
      if (this.webSocketObject.readyState == this.webSocketObject.CONNECTING) {
        console.log("连接正在打开");
      }
      if (this.webSocketObject.readyState == this.webSocketObject.OPEN) {
        console.log("连接已打开");
      }
      //开启心跳
      // this.start();
    },
    // 消息监听
    webSocketOnMessage(e) {
      // console.log("来自服务端的消息->", JSON.parse(e.data).type);
      // let msgData = JSON.parse(e.data);
      //   console.log("msgData->",msgData);
      // if (this.identityObj.identityId != msgData.receiverId) {
      //   console.log("来自服务端的消息->return");

      //   return false;
      // }
      // if(JSON.parse(e.data).receiverA == )
      //消息未读总数
      this.UnMessage();
      // Utils.$emit("MessagesReceived",e)
      // console.log(JSON.parse(e.data).type);
      if (JSON.parse(e.data).type == "new message") {
        // console.log("这是最新的消息");
        this.updateChat(e);
      } else if (JSON.parse(e.data).type == "isAgree") {
        // console.log("isAgree");
        this.updatelist(e);
      } else if (JSON.parse(e.data).type == "isChange") {
        console.log("isChange");
        this.upisChange(e);
      }
    },
    //时间滚动
    timeScroll() {
      const scroll = this.$refs.scroll;
      if (!scroll) {
        // 如果未获取到 scroll 元素，则退出函数
        return;
      }
      const scrollToBottom = () => {
        scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
      };

      // 使用 requestAnimationFrame 替代 setTimeout，确保在下一帧渲染时执行滚动操作
      requestAnimationFrame(() => {
        scrollToBottom();
      });
    },
    upisChange(e) {
      console.log("upisChange", JSON.parse(e.data));
      let newConversation = JSON.parse(JSON.parse(e.data).content);
      // console.log("newConversation", newConversation);
      let pushMessage = Object.assign({}, newConversation.messageList[0]);
      console.log("来自服务端的消息->wxf", pushMessage.status);
      console.log("飞天", newConversation.change);
      console.log("pushMessage", pushMessage);
      if (this.sendParams.conversationId == pushMessage.conversationId) {
        this.dataList.forEach((i) => {
          if (i.conversationId == pushMessage.conversationId) {
            // 在这里执行你想要的操作，例如：
            i.change = newConversation.change;
          }
        });

        // for (let i = 0; i < this.dataList.length; i++) {
        //   // const element = array[index];
        //   if (this.dataList[i].conversationId == pushMessage.conversationId) {
        //     console.log("index", i);
        //     let oldMessage = this.dataList[i];
        //     oldMessage.status = pushMessage.status;
        //     this.$set(this.dataList, i, oldMessage);
        //   }
        // }

        for (let i = 0; i < this.ChatList.length; i++) {
          // const element = array[index];
          if (this.ChatList[i].messageId == pushMessage.messageId) {
            console.log("index", i);
            let oldMessage = this.ChatList[i];
            oldMessage.status = pushMessage.status;
            this.$set(this.ChatList, i, oldMessage);
          }
        }

        // console.log("dataList", this.dataList);
        // console.log("ChatList", this.ChatList);
        this.$nextTick(function () {
          // DOM 更新了
          this.timeScroll();
        });
      }
      // console.log("www", this.ChatList);
    },

    // 更新邀请 ---- 同意 -----拒绝
    updatelist(e) {
      // console.log("e", JSON.parse(e.data));
      let newConversation = JSON.parse(JSON.parse(e.data).content);

      let pushMessage = Object.assign({}, newConversation.messageList[0]);
      console.log("来自服务端的消息->wxf", pushMessage);
      console.log("蓝天", pushMessage);
      console.log("ffff", this.ChatList);
      // if(pushMessage.messageType==8 ){
      //   this.ChatList.forEach((i) => {
      //     if (i.messageId == pushMessage.messageId) {
      //       // 在这里执行你想要的操作，例如：
      //       console.log("晚饭", pushMessage.messageId);
      //       this.$set(this.ChatList,this.ChatList , 'blueberry');
      //     }
      //   });

      for (let i = 0; i < this.ChatList.length; i++) {
        // const element = array[index];
        if (this.ChatList[i].messageId == pushMessage.messageId) {
          console.log("index", i);
          let oldMessage = this.ChatList[i];
          oldMessage.status = pushMessage.status;
          this.$set(this.ChatList, i, oldMessage);
        }
      }

      this.$set(this.items, 1, "blueberry");
      // this.ChatList.push(pushMessage);
      // console.log("this.ChatList", this.ChatList);
      console.log("ChatList", this.ChatList);
      // if(pushMessage.messageType==8 ){
      // }

      // if (this.sendParams.conversationId == pushMessage.conversationId) {

      this.$nextTick(function () {
        // DOM 更新了
        this.timeScroll();
      });
      // }
      // console.log("www", this.ChatList);
    },

    // 实时接收信息
    updateChat(e) {
      console.log("e", JSON.parse(e.data));
      let newConversation = JSON.parse(JSON.parse(e.data).content);
      console.log("newConversation1", newConversation);
      // console.log(
      //   "newConversation",
      //   JSON.parse(newConversation.messageList[0].content)
      // );
      // let pushMessage1 = newConversation.messageList[0];
      // let newArr = pushMessage1;
      // if (pushMessage1.sendType == 2) {
      //   newArr.authName =
      //     pushMessage1.authName.substr(0, 1) +
      //     (pushMessage1.sex == 2 ? "女士" : "先生");
      // } else {
      //   newArr.authName = pushMessage1.authName;
      // }
      // console.log("newConversation1",newConversation);
      let pushMessage = Object.assign({}, newConversation.messageList[0]);
      console.log("来自服务端的消息->wxf", pushMessage);

      // //未读更新
      // this.UnMessage()
      //判断是否是当前会话
      console.log("白云", this.sendParams.conversationId);
      console.log("-------------------------------------");
      console.log("黑云", pushMessage);
      console.log("newConversation2", newConversation);

      // if(pushMessage.messageType==7){
      //   this.ChatList.forEach((i) => {
      //     if (i.messageId == pushMessage.messageId) {
      //       // 在这里执行你想要的操作，例如：
      //       i.status = pushMessage.status;
      //     }
      //   });
      // }
      // if(pushMessage.messageType==8){
      //   this.ChatList.forEach((i) => {
      //     if (i.messageId == pushMessage.messageId) {
      //       // 在这里执行你想要的操作，例如：
      //       i.status = pushMessage.status;
      //     }
      //   });
      // }
      // if(pushMessage.messageType==7){
      //   this.ChatList.forEach((i) => {
      //     if (i.messageId == pushMessage.messageId) {
      //       // 在这里执行你想要的操作，例如：
      //       i.status = pushMessage.status;
      //     }
      //   });
      // }

      if (this.sendParams.conversationId == pushMessage.conversationId) {
        // true push到当前消息列表当中
        pushMessage.receiverType = pushMessage.sendType;
        this.ChatList.push(pushMessage);
        console.log("this.ChatList", this.ChatList);
        // console.log(pushMessage.sex, "这是身份的类型");
        this.$nextTick(function () {
          // DOM 更新了
          this.timeScroll();
        });
      }

      //       let isExist = this.dataList.some(item => {
      //   return item.conversationId == pushMessage.conversationId;
      // });

      // if(isExist){
      //   console.log("有");
      // }else{
      //   console.log('右侧列表中不存在与目标会话相同的内容');
      // }
      //false 判断左侧列表是否有这个会话
      let index = this.checkChatExist(pushMessage.conversationId);
      // console.log(index, "查看值");
      //如果没有会话添加会话
      newConversation.receiverId = newConversation.senderId;
      console.log("wxf----", newConversation.receiverId);
      newConversation.receiver = newConversation.sender;
      console.log("wxf----", newConversation.sendType);
      newConversation.receiverType = pushMessage.sendType;
      newConversation.newContent = getContent(newConversation);
      console.log("newConversation3", newConversation.sendType);
      //false 判断左侧列表是否有这个会话
      let newDataList = this.dataList;
      if (index > -1) {
        newDataList.splice(index, 1);
      }
      newDataList.unshift(newConversation);
      this.dataList = newDataList;
      console.log("www", this.dataList);

      console.log("this.CommunicationIndex", this.CommunicationIndex);
      if (this.CommunicationIndex < index) {
        this.CommunicationIndex++;
        console.log("iiii0", index, this.CommunicationIndex);
      } else if (this.CommunicationIndex == index) {
        this.CommunicationIndex = 0;
        console.log("iiii0", index, this.CommunicationIndex);
      }
      // this.dataList.forEach((i, itemIndex) => {
      //   if (i.conversationId == pushMessage.conversationId) {
      //     // 在这里执行你想要的操作，例如：
      //     // i.change = newConversation.change;
      //   }
      // });
    },
    // 监听报错
    webSocketOnError(e) {
      console.log("与服务端连接异常->", e);
      //重连
      this.reconnect();
    },
    checkChatExist(conversationId) {
      for (let index = 0; index < this.dataList.length; index++) {
        const element = this.dataList[index];
        if (conversationId == element.conversationId) {
          return index;
        }
      }
      return -1;
    },
    // 监听连接关闭
    webSocketOnClose(e) {
      console.log("与服务端连接关闭->", e);
      if (e.code === 1000) {
        console.log("WebSocket连接正常关闭");
      } else if (e.code === 1001) {
        console.log("WebSocket连接由于终端离开而关闭");
      } else if (e.code === 1002) {
        console.log(
          e.code +
            " " +
            e.reason +
            " " +
            e.wasClean +
            "WebSocket连接由于协议错误而关闭"
        );
      } else if (e.code === 1003) {
        console.log("WebSocket连接由于接收到的数据类型错误而关闭");
      } else if (e.code === 1005) {
        console.log("WebSocket连接由于无法获取状态码而关闭");
      } else if (e.code === 1006) {
        console.log("WebSocket连接由于连接被重置而关闭");
        console.log(e);
      } else if (e.code === 1011) {
        console.log("WebSocket连接由于服务器端发生异常而关闭");
      } else if (e.code === 1015) {
        console.log("WebSocket连接由于TLS握手失败而关闭");
      }
      //重连
      this.reconnect();
    },
    // 重新连接
    reconnect() {
      let that = this;
      if (that.lockReconnect) {
        return;
      }
      that.lockReconnect = true;
      //没连接上会一直重连，设置延迟避免请求过多
      that.timeoutnum && clearTimeout(that.timeoutnum);
      that.timeoutnum = setTimeout(function () {
        //新连接
        that.webSocketInit();
        that.lockReconnect = false;
      }, 5000);
    },

    // // // 重置心跳
    // reset() {
    //   var that = this;
    //   //清除时间
    //   clearTimeout(that.timeoutObj);
    //   clearTimeout(that.serverTimeoutObj);
    //   //重启心跳
    //   that.start();
    // },

    // // 开启心跳
    // start() {
    //   var self = this;
    //   self.timeoutObj && clearTimeout(self.timeoutObj);
    //   self.serverTimeoutObj && clearTimeout(self.serverTimeoutObj);
    //   self.timeoutObj = setTimeout(function () {
    //     //这里发送一个心跳，后端收到后，返回一个心跳消息，
    //     if (self.webSocketObject.readyState == 1) {
    //       let Prompt = {
    //         heart: "Hello",
    //       };
    //       let HeartbeatDetection = JSON.stringify(Prompt);
    //       self.webSocketObject.send(HeartbeatDetection);
    //     } else {
    //       //否则重连
    //       self.reconnect();
    //     }
    //     self.serverTimeoutObj = setTimeout(function () {
    //       //超时关闭
    //       self.webSocketObject.close();
    //     }, self.timeout);
    //   }, self.timeout);
    // },
    //分享中的数据
    toDialogData() {
      this.dialogData = [
        {
          title: "我的项目",
          userName: this.identityObj.realName,
          Id: this.identityObj.identityId,
          receiverType: this.identityObj.identityTypeCode,
        },
        {
          title:
            this.sendParams.receiverType == "2"
              ? this.sendParams.receiver.substr(0, 1) +
                (this.sendParams.sex == "2" ? "女士" : "先生") +
                "的项目"
              : this.sendParams.receiver,
          userName: this.sendParams.receiver,
          Id: this.sendParams.receiverId,
          receiverType: this.sendParams.receiverType,
        },
        {
          title: "我的资源资产",
          userName: this.identityObj.realName,
          Id: this.identityObj.identityId,
          receiverType: this.identityObj.identityTypeCode,
        },
        {
          title:
            this.sendParams.receiverType == "2"
              ? this.sendParams.receiver.substr(0, 1) +
                (this.sendParams.sex == "2" ? "女士" : "先生") +
                "的资源资产"
              : this.sendParams.receiver,
          userName: this.sendParams.receiver,
          Id: this.sendParams.receiverId,
          receiverType: this.sendParams.receiverType,
        },
      ];
    },
    //时间
    newTimed() {
      // 获取当前时间
      let now = new Date();
      let year = now.getFullYear();
      let month = (now.getMonth() + 1).toString().padStart(2, "0"); // 月份从0开始，所以要加1
      let day = now.getDate().toString().padStart(2, "0");
      let hour = now.getHours().toString().padStart(2, "0");
      let minute = now.getMinutes().toString().padStart(2, "0");
      let second = now.getSeconds().toString().padStart(2, "0");
      // 格式化时间
      let formattedTime = `${year}-${month}-${day} ${hour}:${minute}:${second}`;

      return formattedTime;
    },
    //发送
    async toSendMessage(type, content) {
      //       console.log("发送", this.identityObj.realName);
      //       console.log("this.dataList[this.CommunicationIndex].receiver",this.dataList[this.CommunicationIndex].receiver);
      // console.log("content",content);

      let params = {
        messageList: [
          {
            //消息类型会话托管
            messageType: type,
            content: content,
          },
        ],

        receiverId: this.dataList[this.CommunicationIndex].receiverId,
        senderType: this.identityObj.identityTypeCode,
        receiverType: this.dataList[this.CommunicationIndex].receiverType,
        senderId: this.identityObj.identityId,
        sender: this.identityObj.realName,
        receiver: this.dataList[this.CommunicationIndex].receiver,
      };

      console.log("params", params);
      console.log("发消息前的ChatList", this.ChatList);
      console.log("dataList", this.dataList);
      let res = await SendMessage(params);
      // console.log("你好", res.data);
      this.addToChatList(content, type, res.data);
      console.log("发消息时的ChatList", this.ChatList);
      // console.log(JSON.parse(JSON.stringify(this.identityObj)));
      // 更新到最底部
      this.dataList[0].unreadCount = 0;
      this.CommunicationIndex = 0;
      this.endSearch();
      this.$nextTick(function () {
        // DOM 更新了
        const scroll = this.$refs.scroll;
        // console.log("查看srcoll看一下有没有", scroll);
        scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
      });
    },
    //push到ChatLit
    addToChatList(content, messageType, num) {
      // console.log(this.identityObj, "这是什么");
      if (this.identityTypeCode == 2) {
        // console.log("this.identityObj.bossName",this.identityObj.bossName);

        this.ChatList.push({
          authName: this.identityObj.realName,
          content: content,
          messageId: num + "",
          messageType: messageType + "",
          senderAuthId: this.identityObj.identityId,
          sendTime: this.newTimed(),
          status: "4",
        });
      } else {
        // console.log("this.identityObj.realName",this.identityObj.realName);
        this.ChatList.push({
          authName: this.identityObj.realName,
          content: content,
          messageId: num + "",
          messageType: messageType + "",
          senderAuthId: this.identityObj.identityId,
          sendTime: this.newTimed(),
          status: "4",
        });
      }
    },

    // 交换电话
    exchange() {
      this.toSendMessage(7, "请求交换电话"); // 7
      // this.endSearch(); //初始化左侧列表
      // console.log(7777);
    },
    // 同意
    async agree(item, type) {
      console.log(this.dataList[this.CommunicationIndex].conversationId);
      const params = {
        agree: true,
        conversionId: this.dataList[this.CommunicationIndex].conversationId,
        messageId: item.messageId,
        receiverId: this.sendParams.receiverId,
        senderType: this.identityObj.identityTypeCode,
        receiverType: this.sendParams.receiverType,
        senderId: this.identityObj.identityId,
        sender: this.identityObj.realName,
        receiver: this.sendParams.receiver,
      };
      if (type == 7) {
        const res = await exchangeTelephone(params);
        console.log("7", res);
      } else if (type == 11) {
        const res = await Agree(params);
        console.log("11", res);
      } else if (type == 12) {
        const res = await agreeRecirculatio(params);
        console.log("12", res);
      }

      this.ChatList.forEach((i) => {
        if (item.messageId == i.messageId) {
          i.status = "3";
        }
      });

      // this.dianhua = res.data;
      // console.log(this.dianhua);
      // this.endSearch(); //初始化左侧列表
      this.RightChat(this.dataList[this.CommunicationIndex]); // 重新给dataList赋值
    },
    // 拒绝
    async Refuse(item, type) {
      console.log(this.dataList[this.CommunicationIndex].conversationId);
      const params = {
        agree: false,
        conversionId: this.dataList[this.CommunicationIndex].conversationId,
        messageId: item.messageId,
        receiverId: this.sendParams.receiverId,
        senderType: this.identityObj.identityTypeCode,
        receiverType: this.sendParams.receiverType,
        senderId: this.identityObj.identityId,
        sender: this.identityObj.realName,
        receiver: this.sendParams.receiver,
      };
      if (type == 7) {
        const res = await exchangeTelephone(params);
        console.log("7", res);
      } else if (type == 11) {
        const res = await Agree(params);
        console.log("11", res);
      } else if (type == 12) {
        const res = await agreeRecirculatio(params);
        console.log("12", res);
      }

      this.ChatList.forEach((i) => {
        if (item.messageId == i.messageId) {
          i.status = "2";
        }
      });
      // console.log("jj", res);
      // this.dianhua = res.data;
      // this.endSearch(); //初始化左侧列表
      this.RightChat(this.dataList[this.CommunicationIndex]); // 重新给dataList赋值
    },
    quxiao() {
      this.isshow = false;
      this.Datalist1 = [];
      this.$router.replace({ path: "/home/Chat" }); // 重定向到根路径
    },
    handleDialogClose() {
      this.isshow = false;
      this.Datalist1 = [];
      this.$router.replace({ path: "/home/Chat" }); // 重定向到根路径
    },

    // 发送项目
    fasong(it, num, type) {
      // if(JSON.stringify(it) == '[object Object]'){
      //   console.log("wxf99999");
      // }
      console.log("9999999999999999999999999", it, num);
      // console.log(this.Datalist1.companyName);
      // this.dataList.forEach((item, index) => {
      //   console.log(this.dataList);
      //   console.log(this.Datalist1);
      //   if (it.crf == item.receiver || it.authId ==item.receiverId) {
      //     // 将匹配的项移动到数组的第一位
      //     //往左侧列表push值
      //     this.dataList.splice(index, 1);
      //     this.dataList.unshift(item);
      //     console.log("router", this.dataList);
      //   }
      // });

      if (num == 1) {
        this.toSendMessage(3, JSON.stringify(it));
      } else if (num == 5) {
        if (type == 9) {
          this.toSendMessage(9, JSON.stringify(it));
        } else {
          this.toSendMessage(10, JSON.stringify(it));
        }
      } else {
        this.toSendMessage(2, JSON.stringify(it));
      }

      this.isshow = false; // 弹窗关闭
      // 在组件中点击后清空路由
      this.$router.replace({ path: "/home/Chat" }); // 重定向到根路径
      // this.endSearch(); //初始化左侧列表
      // console.log("754", item);
      // this.isshow = false;  // 弹窗关闭
      // if (show) {
      //   this.ChatList.forEach((io) => {
      //     if (item.messageId == io.messageId) {
      //       console.log(".....", io.content);
      //       this.toSendMessage(3, io.content);
      //     } else {
      //       this.toSendMessage(2, io.content);
      //     }
      //   });
      // } else {
      //   this.toSendMessage(8, item.content);
      //   this.ChatList.forEach((i) => {
      //     if (item.messageId == i.messageId) {
      //       i.status = "3";
      //     }
      //   });
      // }
      this.Datalist1 = [];
      console.log(1);
      // console.log("UserList",this.UserList);
    },

    // 邀请托管
    yaoqing(item, num, type, boo) {
      console.log(item, num, type, boo);
      this.i = 0;
      if (boo == true) {
        // this.dataList.forEach((item, index) => {
        //   if (it.crf == item.receiver || it.authId ==item.receiverId) {
        //     // 将匹配的项移动到数组的第一位
        //     //往左侧列表push值
        //     this.dataList.splice(index, 1);
        //     this.dataList.unshift(item);
        //     console.log("router", this.dataList);
        //   }
        // });
      }

      console.log("邀请", item, num);
      let removedIdObject = {}; // 创建一个空对象用于存储移除了 id 属性的对象
      if (item) {
        // 如果 item 具有 id 属性，则移除它并存储在 removedIdObject 中
        removedIdObject = { ...item }; // 使用对象解构语法复制 someObject
        delete removedIdObject.id; // 移除 id 属性
      }
      // console.log("removedIdObject", removedIdObject);
      if (num == -1) {
        this.toSendMessage(8, JSON.stringify(removedIdObject));
      } else if (num == 5) {
        //     this.dataList.forEach((item, index) => {
        //     if ( this.Datalist1.companyName == item.receiver) {

        //       // 将匹配的项移动到数组的第一位
        //      //往左侧列表push值
        //      this.dataList.splice(index, 1);
        // this.dataList.unshift(item);
        //       console.log("router",this.dataList);
        //     }
        //   });
        if (type == 10) {
          this.toSendMessage(10, JSON.stringify(item));
        } else {
          this.toSendMessage(9, JSON.stringify(item));
        }
      } else {
        // this.isshow = false; // 弹窗关闭
        // 在组件中点击后清空路由
        this.$router.replace({ path: "/home/Chat" }); // 重定向到根路径
        this.toSendMessage(8, JSON.stringify(removedIdObject));
      }
      this.Datalist1 = [];
      // this.endSearch(); //初始化左侧列表
      this.$router.replace({ path: "/home/Chat" }); // 重定向到根路径
      this.centerDialogVisible = false;
      this.isshow = false; // 弹窗关闭
      // messageType
    },

    jj(item, num) {
      // this.sts = false;
      // console.log("sts", this.sts);
      console.log("jj", item);
      this.Hostingdata(item, false);
      this.ChatList.forEach((i) => {
        if (item.messageId == i.messageId) {
          i.status = num;
        }
      });
    },

    ty(item, num) {
      // this.sts = false;
      // console.log("sts", this.sts);
      console.log("ty", item);
      console.log("this.ChatList.", this.ChatList);

      this.Hostingdata(item, true);
      this.ChatList.forEach((i) => {
        if (item.messageId == i.messageId) {
          i.status = num;
        }
      });
    },

    // 是否同意接管
    async Hostingdata(item, show) {
      console.log(item.messageId);

      let res = await Hosting({
        agree: show,
        conversionId: this.dataList[this.CommunicationIndex].conversationId,
        // messageId: item.messageId,
        messageId: item.messageId,
        // messageList: [
        //   {
        //     //消息类型会话托管
        //     messageType: 8,
        //     // content: JSON.stringify(item),
        //   },
        // ],
        receiverId: this.sendParams.receiverId,
        senderType: this.identityObj.identityTypeCode,
        receiverType: this.sendParams.receiverType,
        senderId: this.identityObj.identityId,
        sender: this.identityObj.realName,
        receiver: this.sendParams.receiver,
      });
      if (res.code == 0) {
        if (res.data.code == 0) {
          this.$message({
            message: res.data.msg,
            type: "success",
          });
        } else if (res.data.code == 1) {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
          // 在已经接管的不变
          this.ChatList.forEach((i) => {
            if (item.messageId == i.messageId) {
              i.status = "1";
            }
          });
        }
        // else if(res.status!=200){
        //   this.ChatList.forEach((i) => {
        //     if (item.messageId == i.messageId) {
        //       i.status = "1";
        //     }
        //   });
        // }
        else if (res.data.code == 2) {
          this.$message.error(res.data.msg);
        }
      } else {
        this.$message.error(res.data.msg);
      }
      console.log(res);
    },

    //这是弹窗托管
    closeDialog1() {
      this.showDetail = false;
    },
    closeDialog2() {
      this.showDetail2 = false;
    },
    // 站内信息
    // closeDialog3() {
    //   this.showDetail3 = false;
    // },
    // 站内信息
    // detailClick3(val, num) {
    //   this.showDetail3 = true;
    //   num;
    //   this.$nextTick(() => {
    //     this.$refs.showDetail3.init(
    //       val,
    //       this.dataList[this.CommunicationIndex].receiverId
    //       // "求购信息详情"
    //     );
    //   });
    // },
    //点击弹窗接管
    detailClick(val) {
      this.showDetail = true;
      this.$nextTick(() => {
        this.$refs.showDetail.init(
          val,
          this.dataList[this.CommunicationIndex].receiverId
        );
      });
      // console.log(val, "进行1");
    },
    detailClick2(val, num) {
      this.showDetail2 = true;
      num;
      this.$nextTick(() => {
        this.$refs.showDetail2.init(
          val,
          this.dataList[this.CommunicationIndex].receiverId,
          "求购信息详情",
          this.dataList[this.CommunicationIndex].receiverType
        );
      });
    },
    // 跳转到竞价页面
    tiaozhuan(item) {
      this.$router.push({ name: "projectDetail", query: { c: item.xmbh } });
    },

    // 搜索好友
    // async endSearch() {
    //   let params = {
    //     userName: this.input,
    //     receiverId: this.identityObj.identityId,
    //   };
    //   let res = await SearchItem(params);
    //   console.log("查看左侧列表", res.data);
    //   this.dataList = res.data.map((item) => {
    //     return {
    //       ...item,
    //       newContent: getContent(item),
    //       // newContent: item,
    //     };
    //   });
    //   this.RightChat(this.dataList[0]);
    //   this.UnMessage();
    // },
    async endSearch() {
      try {
        this.loading = true; // 开始加载时显示加载动画
        let params = {
          userName: this.input,
          receiverId: this.identityObj.identityId,
        };
        let res = await SearchItem(params);
        console.log("查看左侧列表", res.data);
        this.dataList = res.data.map((item) => {
          return {
            ...item,
            newContent: getContent(item),
            // newContent: item,
          };
        });

        this.RightChat(this.dataList[0]);
        this.UnMessage();
        console.log(JSON.parse(JSON.stringify("111111111", this.Datalist1)));
        this.dataList.forEach((item, index) => {
          console.log("//**", item);

          if (
            this.Datalist1 &&
            (this.Datalist1.authId == item.receiverId ||
              this.Datalist1.identityId == item.receiverId)
          ) {
            // this.Datalist1.crf == item.receiver  ||
            // 将匹配的项移动到数组的第一位
            //往左侧列表push值
            this.dataList.splice(index, 1);
            this.dataList.unshift(item);
            console.log("router", this.dataList);
          }
        });
      } catch (error) {
        console.error("请求数据出错:", error);
      } finally {
        this.loading = false; // 请求结束后隐藏加载动画
      }
    },
    //查询右侧列表
    async RightChat(item) {
      let conversationParams = {
        conversationId: item.conversationId,
        senderAuthId: this.identityObj.identityId,
      };
      let res = await DialogueContent(conversationParams);
      // 总的未读消息刷新
      this.UnMessage();
      console.log("res", res);
      this.ChatList = res.data;
      this.sendParams = item;
      this.$nextTick(function () {
        // DOM 更新了
        const scroll = this.$refs.scroll;
        scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
      });
    },

    //左侧消息点击
    async ChangeTap(item, index) {
      this.CommunicationIndex = index;
      console.log("CommunicationIndex", index);
      //左侧未读消息
      this.RightChat(item);
      this.dataList[0].unreadCount = 0;
      this.dataList[index].unreadCount = 0;
      // this.UnMessage();
      //点击的变为可以请求更多数据
      this.requestMoreMessage = true;
      //上拉加载更多消息内容
      let upLoadText = this.$refs.upLoadText;
      upLoadText.innerHTML = "向上滚动加载更多消息......";
      this.$nextTick(function () {
        // DOM 更新了
        this.timeScroll();
      });
    },
    // 发送聊天
    async addBtn() {
      if (
        this.ChatMessage.trim() === "" ||
        this.ChatMessage == null ||
        this.ChatMessage == "\n"
      ) {
        this.$message.warning(`发送的消息不能为空`);
        this.ChatMessage = "";
      } else {
        // this.UnMessage();
        this.SET_MESSAGE(0); // 让发送消息时总条数变成零
        // console.log("1");
        this.dataList[0].unreadCount = 0;
        this.toSendMessage(0, this.ChatMessage);
        //往左侧列表push值
        let newDataList = this.dataList;
        let pushItem = newDataList[this.CommunicationIndex];
        pushItem.newContent = this.ChatMessage;
        pushItem.messageList[0].content = this.ChatMessage;
        // console.log(pushItem, "pushItem");
        newDataList.splice(this.CommunicationIndex, 1);
        newDataList.unshift(pushItem);
        this.dataList = newDataList;
        //  //添加到右侧列表
        this.ChatMessage = "";
      }
    },

    // 上传图片
    async handleChange(file, filelist, type) {
      // console.log(file, filelist, type, "打印图片");
      let res = await this.uploadFile(file, filelist, "Chat");
    },
    //文件上传
    async handleChange2(file) {
      let param = new FormData();
      param.append("catalog", "Chat");
      param.append("file", file.raw);
      // console.log("打印file,打印param", file, param);
      let res = await uploadFile(param);
      // console.log("这是后端返回的数据", res);
      this.toSendMessage(6, JSON.stringify(res.data));
    },
    //上传的文件下载
    download(item) {
      // console.log('点击',item)
      // console.log(item);
      // const url = item.urlView;
      const url =
        this.baseUrl +
        "admin/file/get?ossFilePath=" +
        JSON.parse(item.content).url;
      // console.log(url);
      let dom = document.createElement("a");
      dom.style.display = "none";
      dom.href = url;
      dom.setAttribute("download", JSON.parse(item.content).sourceFileName);
      document.body.appendChild(dom);
      dom.click();
    },
    //做图片上传
    async uploadFile(file) {
      let param = new FormData();
      param.append("catalog", "Chat");
      param.append("file", file.raw);
      let res = await uploadFile(param);
      let imgUrl = "/api/admin/file/get?ossFilePath=" + res.data.url;
      const content = {
        imgUrl: imgUrl,
        authName: this.identityObj.bossName,
      };
      this.toSendMessage(1, JSON.stringify(content));
    },
    //初始化分享项目内容
    async createShareList() {
      let params = {
        userAuthId: this.identityObj.identityId,
        projectName: "",
        receiverType: this.identityObj.identityTypeCode,
      };
      let res = await FarmerSharing(params);
      if (res) {
        this.UserList = res.data;
        this.UserList.forEach((order) => {
          if (order.orderType == "1") {
            order.orderTypeName = "全程托管";
          } else {
            order.orderTypeName = "环节托管";
          }
          order.receiverType = params.receiverType ? params.receiverType : "";
          if (order.receiverType == 2) {
            //是农户时剪切时间
            order.dayNum = order.startDate ? order.startDate.substr(8, 2) : "";
            order.yearNum = order.startDate ? order.startDate.substr(0, 4) : "";
            order.monthNum = order.startDate
              ? order.startDate.substr(5, 2)
              : "";
          } else if (order.receiverType == 4) {
            //是公司时剪切时间
            order.dayNum = order.createTime
              ? order.createTime.substr(8, 2)
              : "";
            order.yearNum = order.createTime
              ? order.createTime.substr(0, 4)
              : "";
            order.monthNum = order.createTime
              ? order.createTime.substr(5, 2)
              : "";
          }
        });
      } else {
        this.UserList = [];
      }
    },

    //分享弹窗
    ShareList() {
      this.i = 0;

      this.centerDialogVisible = true;
      this.toDialogData();
      this.$nextTick(function () {
        // DOM 更新了
        this.createShareList();
      });
    },
    //弹窗内容
    async shareBtn(item, index) {
      //点击切换并添加高亮
      this.i = index;
      let params = {
        userAuthId: item.Id,
        projectName: "",
        receiverType: item.receiverType,
      };

      if (index == 0 || index == 1) {
        let res = await FarmerSharing(params);
        // console.log("这是res的数据", res.data);
        this.UserList = res.data;
        this.UserList.forEach((order) => {
          if (order.orderType == "1") {
            order.orderTypeName = "全程托管";
          } else {
            order.orderTypeName = "环节托管";
          }
          order.receiverType = item.receiverType ? item.receiverType : "";
          if (order.receiverType == 2) {
            //是农户时剪切时间
            order.dayNum = order.startDate ? order.startDate.substr(8, 2) : "";
            order.yearNum = order.startDate ? order.startDate.substr(0, 4) : "";
            order.monthNum = order.startDate
              ? order.startDate.substr(5, 2)
              : "";
          } else if (order.receiverType == 4) {
            //是公司时剪切时间
            order.dayNum = order.createTime
              ? order.createTime.substr(8, 2)
              : "";
            order.yearNum = order.createTime
              ? order.createTime.substr(0, 4)
              : "";
            order.monthNum = order.createTime
              ? order.createTime.substr(5, 2)
              : "";
          }
        });
      } else if (index == 2 || index == 3) {
        console.log(index);
        let res = await jingjia({ userAuthId: params.userAuthId });
        this.UserList = res.data;
        console.log("res", res);
        this.UserList.forEach((order) => {
          order.receiverType = 3;
          order.dayNum = order.createTime ? order.createTime.substr(8, 2) : "";
          order.yearNum = order.createTime ? order.createTime.substr(0, 4) : "";
          order.monthNum = order.createTime
            ? order.createTime.substr(5, 2)
            : "";
        });
      } else {
        this.UserList = [];
      }
    },
    //发送分享的项目
    async shareSend(item, num) {
      console.log("666", item);

      // console.log(this.identityObj.identityTypeCode);
      // console.log(item);
      //农户只能托管，公司只能接管
      if (item.receiverType == "2") {
        // console.log("这是个人分享的项目");
        this.toSendMessage(2, JSON.stringify(item));
        // 关闭弹窗
        // this.centerDialogVisible = false;
      } else if (item.receiverType == "4") {
        this.toSendMessage(3, JSON.stringify(item));
        // this.centerDialogVisible = false;
        // console.log("这是公司分享项目");
      } else if (num == 9) {
        this.toSendMessage(9, JSON.stringify(item));
      }
      // 关闭弹窗
      this.centerDialogVisible = false;
      this.i = 0;
    },
    //搜索查询
    async shareFind() {},
    //查询总的未读消息
    async UnMessage() {
      let params = {
        receiverId: this.identityObj.identityId,
      };
      let res = await UnreadMessage(params);
      this.AllMessages = res.data;
      // console.log("调用了未读消息");
      this.SET_MESSAGE(this.AllMessages);
    },
    // 上拉加载更多
    async fetchData(scroll) {
      console.log(this.ChatList);
      if (this.ChatList.length <= 0) {
        console.log(1);
        this.loading = false;
        return;
      }
      console.log(2);
      this.loading = true;
      let params = {
        conversationId: this.dataList[this.CommunicationIndex].conversationId,
        messageId: this.ChatList[0].messageId,
        // messageId:"20",
        senderAuthId: this.identityObj.identityId,
      };

      let res = await DialogueContent(params);
      // console.log("res.",res);
      let oldHeight = scroll.scrollHeight;
      let requestMoreMessageLength = res.data.length;
      if (requestMoreMessageLength < 10) {
        this.requestMoreMessage = false;
        let upLoadText = this.$refs.upLoadText;
        upLoadText.innerHTML = "到底啦，您暂无更多历史消息";
      }
      this.ChatList.unshift(...res.data);

      this.$nextTick(function () {
        // DOM 更新了
        let newHeight = scroll.scrollHeight;
        scroll.scrollTop = newHeight - oldHeight;
      });
      this.loading = false;
    },
    //上拉事件
    handleScroll() {
      const scroll = this.$refs.scroll;
      if (scroll.scrollTop < 1 && !this.loading && this.requestMoreMessage) {
        //固定一个元素到聊天框中间
        this.loading = true;
        //当scrollTop=0的时候内容显示加载中或者到顶了
        this.fetchData(scroll);
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-loading-mask {
  z-index: 9999 !important;
}
.box1 {
  /* 设置元素的高度和宽度，以便容纳内容并定义滚动区域 */
  height: 555px; /* 例如，设置高度为200像素 */
  width: 100%; /* 例如，设置宽度为300像素 */

  /* 添加滚动条，根据需要自动显示 */
  overflow-y: auto; /* 仅在需要时显示垂直滚动条 */
}
// ico 图标
@font-face {
  font-family: "iconfont"; /* Project id 4474772 */
  src: url("//at.alicdn.com/t/c/font_4474772_xhl3vwub83.woff2?t=1710927807601")
      format("woff2"),
    url("//at.alicdn.com/t/c/font_4474772_xhl3vwub83.woff?t=1710927807601")
      format("woff"),
    url("//at.alicdn.com/t/c/font_4474772_xhl3vwub83.ttf?t=1710927807601")
      format("truetype");
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-lianxiren:before {
  content: "\e654";
}

.icon-fenxiang:before {
  content: "\e739";
}

.icon-wenjianshangchuan:before {
  content: "\e616";
}

.icon-zhongbo:before {
  content: "\e710";
}

.icon-shangchuantupian:before {
  content: "\e61e";
}

// 交换电话卡片
.box-card {
  border-radius: 10px;
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.card-header {
  background-color: #f0f0f0;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card-content {
  padding: 20px;
}
.request-text {
  font-size: 16px;
  margin-bottom: 20px;
  text-align: center;
}
.button-group {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.reject-button {
  background-color: #ff4949;
  color: #fff;
  border: 1px solid #ff4949;
}
.accept-button {
  background-color: #67c23a;
  color: #fff;
  border: 1px solid #67c23a;
}

// -----------------------------------------------
body {
  overflow: hidden;
  overflow-y: auto;
}
// .el-loading-mask {
//   z-index: 9999  !important; /* 设置较高的 z-index */
// }
// .el-dialog__wrapper {
//   z-index: 2007 !important; /* 设置较低的 z-index，使其显示在 v-loading 下面 */
// }
// .el-dialog--center {
//   z-index: 5000 !important; /* 设置较低的 z-index，使其显示在 v-loading 下面 */
// }
.chat {
  width: 100%;
  height: 670px;
  display: flex;
  margin: 20px 0px;
  justify-content: space-around;
  .Left {
    width: 24%;
    height: 100%;
    border: solid 1px #ccc;
    border-bottom: 0;
    background: white;
    .title {
      width: 100%;
      height: 50px;
      text-align: center;
      line-height: 50px;
      font-weight: bold;
      background: #f7f8fa;
    }
    .SearchChat {
      background: #f7f8fa;
      width: 100%;
      height: 60px;
      display: flex;
      justify-content: space-around;
      line-height: 50px;
      .SearchInput {
        width: 85%;
        height: 50px;
        /deep/.el-input__inner {
          border-radius: 20px 0px 0px 20px;
          height: 35px;
          border-right: none;
          .el-input-group__append {
            border-radius: 20px 0px 0px 20px;
          }
        }
      }
    }
    .ChatContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      // box-sizing: border-box;
      .ChatItem {
        // height: auto;
        border-bottom: 0.5px solid #7c7979;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        // margin-bottom: 10px;
        .imgs {
          i {
            font-size: 60px;
          }
        }
        .content {
          box-sizing: border-box;
          width: 90%;
          display: flex;
          flex-direction: column;
          margin-left: 3%;
          .UserTop {
            width: 100%;
            // display: flex;
            // justify-content: space-between;
            .user {
              font-weight: bolder;
            }
            .timeData {
              width: 40%;
              font-size: 0.9em;
              flex-wrap: nowrap;
              text-align: right;
            }
          }
          .ChatMessage {
            width: 90%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 15px;
          }
        }
      }
      .active {
        // height: auto;
        background: #e6e9ed;
      }
    }
    .Loading {
      width: 100%;
      text-align: center;
    }
  }
  .Right {
    width: 75%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .top {
      width: 100%;
      height: 80%;
      .userName {
        width: 100%;
        height: 50px;
        line-height: 50px;
        font-weight: bold;
        flex-wrap: nowrap;
        border-bottom: 1px solid #cccc;
      }
      .ChatContent {
        width: 100%;
        height: 476px;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        .upLoadText {
          width: 100%;
          height: 20px;
          margin-top: 20px;
          text-align: center;
        }
        /* 文本类型 */
        .zeroStyle {
          width: 100%;
          display: flex;
          align-items: center;
          margin-top: 20px;
          .Item {
            width: 95%;
          }
          .ChatLeft {
            float: left;
            .user {
              span {
                margin: 0 2px;
                font-size: 15px;
              }
            }
            .content {
              float: left;
              // max-width: 400px;
              // min-height: 50px;
              display: flex;
              align-items: center;
              span {
                padding: 10px;
                border-radius: 8px;
                float: right;
                max-width: 500px;
                word-wrap: break-word;
                background: white;
              }
            }
          }
          .ChatRight {
            float: right;
            .user {
              span {
                margin: 0 2px;
                font-size: 15px;
              }
            }
            .content {
              float: right;
              // max-width: 500px;
              // min-height: 50px;
              display: flex;
              align-items: center;
              span {
                padding: 5px 10px;
                border-radius: 8px;
                float: right;
                max-width: 400px;
                word-wrap: break-word;
                background: #fff;
              }
            }
          }
        }
        /* 图片类型 */
        .firstStyle {
          width: 99%;
          .BoxClass {
            width: 400px;
            .Item {
              width: 95%;
            }
          }
          .ChatLeft {
            float: left;
            .BoxClass {
              width: 400px;
              display: flex;
              flex-direction: column;
              justify-content: space-around;
            }
          }
          .ChatRight {
            float: right;
            .BoxClass {
              width: 400px;
              display: flex;
              flex-direction: column;
              justify-content: space-around;
            }
          }
        }
        /* 分享项目托管 */
        .secondStyle {
          width: 100%;
          .Item {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            box-sizing: border-box;
            width: 45%;
            margin-top: 20px;
            .chats {
              width: 99%;
              display: flex;
              flex-direction: column;
              .user {
                span {
                  margin-right: 10px;
                }
                margin-left: 10px;
              }
              .context {
                flex-direction: column;
                justify-content: space-around;
              }
            }
          }
          .ChatRight {
            float: right;
            display: flex;
            justify-content: space-around;
            .context {
              // box-sizing: border-box;
              // width: 500px;
              background: #fff;
              border-radius: 5px;
              .content {
                p {
                  span {
                    font-weight: bold;
                  }
                }
              }
              .findBtn {
                display: flex;
                flex-direction: column;
                // justify-content: space-around;
              }
            }
          }
          .ChatLeft {
            float: left;
            display: flex;
            justify-content: space-around;
            border-radius: 5px;

            .context {
              // box-sizing: border-box;
              // width: 500px;
              background: white;
              border-radius: 8px;
              .content {
                p {
                  span {
                    font-weight: bold;
                  }
                }
              }
              .findBtn {
                display: flex;
                flex-direction: column;
                // justify-content: space-around;
              }
            }
          }
        }
        /* 分享项目接管 */
        .ThirdStyle {
          // width: 78%;
          .Item {
            width: 45%;
            margin-top: 20px;
            display: flex;
            .user {
              span {
                margin-right: 10px;
              }
            }
          }
          .ChatLeft {
            width: 98%;
            // background: #000;
            // float: left;
            // display: flex;
            // justify-content: space-around;
            .chats {
              width: 45%;
              float: left;

              .context {
                display: flex;
                background: white;
                box-sizing: border-box;
                // padding: 10px;
                // width: 500px;
                border-radius: 8px;
                .content {
                  p {
                    span {
                      font-weight: bold;
                    }
                  }
                }
              }
              .rightDetail {
                display: flex;
                flex-direction: column;
                // justify-content: space-around;
              }
            }
          }
          .ChatRight {
            float: right;
            display: flex;
            justify-content: space-around;
            border-radius: 8px;
            .chats {
              width: 98%;
              .user {
                span {
                  margin-right: 10px;
                }
                margin-left: 10px;
              }
              .context {
                // box-sizing: border-box;
                // display: flex;
                // width: 500px;
                // padding: 10px 20px;
                // justify-content: space-evenly;
                background: #fff;
                border-radius: 10px;
                .content {
                  p {
                    span {
                      font-weight: bold;
                    }
                  }
                }
              }
            }
            .rightDetail {
              display: flex;
              flex-direction: column;
              // justify-content: space-around;
            }
          }
          .rightDetail {
            display: block;
          }
        }
        /* 发起会话接管类型 */
        .FourthStyle {
          width: 100%;
          margin: 10px 0;
          .Item {
            width: 43.5%;
            // display: flex;
            //  margin: 0 auto;
            // justify-content: space-evenly;
            // align-items: center;
            .chats {
              width: 100%;
              .content {
                display: flex;
                border: solid 1px #ccc;
                justify-content: space-around;

                .header {
                  p {
                    span {
                      font-weight: bold;
                    }
                    .kong {
                      padding-right: 20px;
                    }
                  }
                }
                .rightDetail {
                  display: flex;
                  flex-direction: column;
                  justify-content: space-around;
                }
              }
            }
          }
        }
        /* 发起会话托管类型 */
        .FifthStyle {
          width: 100%;
          margin: 10px 0;
          .Item {
            width: 50%;
            display: flex;
            margin: 0 auto;
            justify-content: space-evenly;
            align-items: center;
            // border: solid 1px #ccc;
            .chats {
              width: 100%;
              .content {
                display: flex;
                justify-content: space-around;
                .header {
                  p {
                    span {
                      font-weight: bold;
                    }
                  }
                }
                .findBtn {
                  display: flex;
                  flex-direction: column;
                  justify-content: space-around;
                }
              }
            }
          }
        }
        /*文件消息类型*/
        .Sixth {
          width: 100%;
          display: flex;
          margin-top: 10px;
          align-items: center;
          .Item {
            width: 96%;
            .findBtn {
              display: none;
            }
          }
          .chats {
            width: 45%;
            float: left;
            display: flex;
            flex-direction: column;
          }
          .ChatLeft {
            // float: left;
            .user {
              span {
                margin: 0 2px;
                font-size: 15px;
              }
            }
            .Download {
              width: 100%;
              height: 50px;
              border-radius: 8px;
              background: white;
              cursor: pointer;
              p {
                span {
                  padding: 0px 10px;
                }
                i {
                  line-height: 50px;
                  color: #409eff;
                  font-size: 20px;
                  padding: 0px 10px;
                }
                color: white;
                display: flex;
                align-content: space-between;
                justify-content: space-around;
                line-height: 50px;
                span {
                  color: #409eff;
                  text-decoration: underline;
                }
              }
            }
          }
          .ChatRight {
            width: 97%;
            /* float: left; */
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-content: flex-end;

            .user {
              span {
                margin: 0 2px;
                font-size: 15px;
              }
            }
            .Download {
              width: 100%;
              height: 50px;
              border-radius: 8px;
              background: #ffffff;
              cursor: pointer;
              p {
                span {
                  padding: 0px 10px;
                }
                i {
                  // line-height: 50px;
                  color: #409eff;
                  font-size: 20px;
                  padding: 0px 10px;
                }
                color: white;
                display: flex;
                align-content: space-between;
                justify-content: space-around;
                // line-height: 50px;
                span {
                  color: #409eff;
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
    .loading {
      height: 80%;
    }
    .bottom {
      width: 100%;
      // height: 20%;
      margin-top: 12px;
      margin-bottom: 20px;
      background: #f2f2f2;
      .UploadOrShare {
        ul {
          display: flex;
          li {
            list-style: none;
            width: 50px;
            height: 50px;
            margin-right: 40px;
            // img {
            //   width: 40px;
            //   height: 30px;
            // }
          }
        }
      }
      .sendChat {
        width: 100%;
        display: flex;
        align-items: center;
        .ipt {
          width: 80%;
          width: 50%;
        }
        border .BtnChat {
          margin-left: 3%;
        }
      }
    }
  }
}
.dialog {
  height: 400px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  .header {
    width: 100%;
    display: flex;
    margin: 10px 0;
    div {
      width: 50%;
      height: 25px;
      text-align: center;
      border: solid 1px #ccc;
      border-radius: 5px;
    }
    .active {
      background: #2863ab;
      color: white;
    }
  }
  .inputSearch {
    width: 90%;
  }
  /deep/.el-input__inner {
    border-radius: 20px;
    height: 35px;
  }
  .ItemList {
    width: 100%;
    margin: 10px 0;

    .TypeStyle {
      display: flex;
      justify-content: space-around;
    }

    .timeBefore {
      width: 80px;
      height: 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      span {
        font-size: 30px;
        margin-top: 5px;
      }
      p {
        font-size: 10px;
      }
      background: #7f7f7f;
      color: white;
      font-weight: bold;
    }
    .detailsItem {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .ItemTitle {
        width: 350px;
      }
      .ItemLs {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
</style>
